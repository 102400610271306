// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// application
import PageHeader from "../shared/PageHeader";
import Pagination from "../shared/Pagination";
import PostCard from "../shared/PostCard";
import BlogSidebar from "./BlogSidebar";

// data stubs
import posts from "../../data/blogPosts";
import theme from "../../data/theme";

const BlogPageCategory = (props) => {
    const search = props.location.search; // could be '?foo=88888'
    const params = new URLSearchParams(search);
    let currentPage = params.get("page");

    if (!currentPage) currentPage = 1;

    let itemsPerPage = 5;

    let pagesTotal = Math.ceil(posts.length / itemsPerPage);
    console.log(pagesTotal);
    console.log(currentPage);

    const layout = "classic";
    const sidebarPosition = "end";

    const breadcrumb = [
        { title: "Главная", url: "/" },
        { title: "Блог", url: "/blog" },
        { title: "Свежие новости", url: "" },
    ];

    let sidebarEnd;
    const sidebar = <BlogSidebar position={sidebarPosition} />;
    sidebarEnd = <div className="col-12 col-lg-4">{sidebar}</div>;

    const postsList = posts.map((post) => {
        const postLayout = {
            classic: "grid-lg",
            grid: "grid-nl",
            list: "list-nl",
        }[layout];

        if (post.id > currentPage * itemsPerPage - itemsPerPage && post.id <= currentPage * itemsPerPage) {
            return (
                <div key={post.id} className="posts-list__item">
                    <PostCard post={post} layout={postLayout} />
                </div>
            );
        } else return;
    });

    const handlePageChange = () => {
        console.log("page change");
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Блог — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Свежие новости" breadcrumb={breadcrumb} />

            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="block">
                            <div className="posts-view">
                                <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                    <div className="posts-list__body">{postsList}</div>
                                </div>

                                <div className="posts-view__pagination">
                                    <Pagination
                                        current={currentPage}
                                        siblings={2}
                                        total={pagesTotal}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {sidebarEnd}
                </div>
            </div>
        </React.Fragment>
    );
};
export default BlogPageCategory;
