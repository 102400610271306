// react
import React from "react";
import { useParams } from "react-router";

// third-party
import { Helmet } from "react-helmet";

// application
import CategorySidebar from "./CategorySidebar";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";

// data stubs
import products from "../../data/shopProducts";

import theme from "../../data/theme";

function ShopPageSearch(props) {
    let { str } = useParams();
    console.log(products);
    console.log(str);

    let nameSearchProducts = products.filter(item => {
        const reg = new RegExp(str, "i");
        return item.name.search(reg) !== -1;
    });

    let idSearchProducts = products.filter(item => {
        return item.id === Number(str);
    });

    const viewMode = "grid";
    const sidebarPosition = "start";

    const breadcrumb = [
        { title: "Главная", url: "/" },
        { title: `Поиск ${str}`, url: `/shop/search/:${str}` }
    ];
    let content;

    const offcanvas = "mobile";

    const sidebar = <div className="shop-layout__sidebar">{<CategorySidebar offcanvas={offcanvas} />}</div>;

    let idSearchRes = "";
    let strSearchRes = "";
    let msgNoResults = <h4>По Вашему запросу "{str}" ничего не найдено :(</h4>;

    if (idSearchProducts.length || nameSearchProducts.length) msgNoResults = "";

    if (idSearchProducts.length)
        idSearchRes = (
            <div>
                <h4>Результаты поиска по ID товара: "ID{str}"</h4>
                <div className="block">
                    <ProductsView
                        products={idSearchProducts}
                        layout={viewMode}
                        grid="grid-3-sidebar"
                        limit={15}
                        offcanvas={offcanvas}
                    />
                </div>
            </div>
        );

    if (nameSearchProducts.length)
        strSearchRes = (
            <div>
                <h4>Результаты поиска по названию товара "{str}"</h4>
                <div className="block">
                    <ProductsView
                        products={nameSearchProducts}
                        layout={viewMode}
                        grid="grid-3-sidebar"
                        limit={15}
                        offcanvas={offcanvas}
                    />
                </div>
            </div>
        );

    content = (
        <div className="container">
            <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                {sidebarPosition === "start" && sidebar}
                <div className="shop-layout__content">
                    {msgNoResults}
                    {idSearchRes}
                    {strSearchRes}
                </div>
                {sidebarPosition === "end" && sidebar}
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Поиск — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={`Результаты поиска`} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

export default ShopPageSearch;
