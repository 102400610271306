// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// application
import departmentsAria from "../../services/departmentsArea";
import SlickWithPreventSwipeClick from "../shared/SlickWithPreventSwipeClick";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1
};

export default class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia("(min-width: 992px)");

    slides = [
        {
            title: "Строительные<br>ленты",
            text: "Огромный ассортимент<br>Наивысшее качество",
            image_classic: "images/slides/slide-1.jpg",
            image_full: "images/slides/slide-1-full.jpg",
            image_mobile: "images/slides/slide-1-mobile.jpg"
        },
        {
            title: "Стрейч<br>на любой вкус",
            text: "Идеально<br>для вашего бизнеса",
            image_classic: "images/slides/slide-2.jpg",
            image_full: "images/slides/slide-2-full.jpg",
            image_mobile: "images/slides/slide-2-mobile.jpg"
        },
        {
            title: "Ленты<br>вспененные",
            text: "Двухсторонние клейкие<br>ленты 100% надежности",
            image_classic: "images/slides/slide-3.jpg",
            image_full: "images/slides/slide-3-full.jpg",
            image_mobile: "images/slides/slide-3-mobile.jpg"
        }
    ];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener("change", this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = ref => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { withDepartments } = this.props;

        const blockClasses = classNames("block-slideshow block", {
            "block-slideshow--layout--full": !withDepartments,
            "block-slideshow--layout--with-departments": withDepartments
        });

        const layoutClasses = classNames("col-12", {
            "col-lg-12": !withDepartments,
            "col-lg-9": withDepartments
        });

        const slides = this.slides.map((slide, index) => {
            const image = withDepartments ? slide.image_classic : slide.image_full;

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${image})`
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.image_mobile})`
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div className="block-slideshow__slide-text" dangerouslySetInnerHTML={{ __html: slide.text }} />
                        {/*
                        <div className="block-slideshow__slide-button">
                            <Link to="/" className="btn btn-primary btn-lg">Shop Now</Link>
                        </div>
                        */}
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <SlickWithPreventSwipeClick {...slickSettings}>{slides}</SlickWithPreventSwipeClick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool
};

BlockSlideShow.defaultProps = {
    withDepartments: false
};
