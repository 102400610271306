// react
import React from "react";

// third-party
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// data stubs
import theme from "../../data/theme";

function SitePageNotFound() {
    return (
        <div className="block">
            <Helmet>
                <title>{`404 Страница не найдена — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">Ууупс! Ошбка 404</div>

                    <div className="not-found__content">
                        <h1 className="not-found__title">Страница не найдена</h1>

                        <p className="not-found__text">
                            Мы не можем найти страницу, которую вы ищете.
                            {/*
                            <br />
                            Try to use the search.
                        */}
                        </p>
                        {/*
                        <form className="not-found__search">
                            <input type="text" className="not-found__search-input form-control" placeholder="Search Query..." />
                            <button type="submit" className="not-found__search-button btn btn-primary">Search</button>
                        </form>
                        */}
                        <p className="not-found__text">Перейдите на домашнюю страницу, чтобы начать все сначала.</p>

                        <Link to="/" className="btn btn-secondary btn-sm">
                            Перейти на домашнюю страницу
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
