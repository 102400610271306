import React from "react";
import { useParams } from "react-router";

import shopCategories from "../../data/shopCategories";

// third-party

import { Helmet } from "react-helmet";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";

// data stubs
import categories from "../../data/shopWidgetCategories";
import products from "../../data/shopProducts";
import theme from "../../data/theme";

const ItemView = props => {
    let { id } = useParams();

    const { layout } = props;

    //Визначаємо товар за адресою
    let product;
    if (id) {
        product = products.find(x => x.id === parseFloat(id));
    } else {
        product = products[products.length - 1];
    }
    //console.log(product);

    if (product === undefined) {
        props.history.push("/404");
        return 0;
    }

    //Визначаємо категорію за товаром
    let category;
    category = shopCategories.find(x => x.id === parseFloat(product.category));
    //console.log(category);

    //Формуємо хлібні крихти
    const breadcrumb = [
        { title: "Главная", url: "" },
        //{ title: category.name, url: "" },
        { title: product.name, url: "" }
    ];
    //console.log(breadcrumb);

    let content;

    content = (
        <React.Fragment>
            <div className="block">
                <div className="container">
                    <Product product={product} layout={layout} />
                    <ProductTabs product={product} />
                </div>
            </div>

            {/*
            <BlockProductsCarousel title="Related Products" layout="grid-5" products={products} />
            */}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
};
export default ItemView;
