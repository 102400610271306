// react
import React from "react";

// third-party

import { connect } from "react-redux";

// data stubs
const mystyle = {
    textAlign: "justify",
};

function HomePageArticle(props) {
    let content;

    content = (
        <div className="container">
            <div className="block">
                <h2>Техническая клейкая лента: виды, возможности, цена</h2>
                <p style={mystyle}>
                    Компания SVETLA презентует Украине качественный технический скотч от отечественного производителя. В
                    отличие от привычной канцелярской клейкой ленты, он обладает целым рядом модификаций. Уникальные
                    качества, полученные путем использования инновационных технологий, позволили расширить сферы
                    применения данного материала.
                </p>
                <h3>Виды</h3>
                <p style={mystyle}>
                    На сайтах и в строительных супермаркетах представлено более 10 разных вариантов клейкой ленты от
                    разных производителей из стран Европы, Америки, Азии и, конечно, Украины. Всех их можно разделить на
                    несколько групп в зависимости от определенной характеристики.
                </p>
                <p>И так, основываясь на клеящей базе, можно выделить такие виды скотча:</p>
                <ul>
                    <li>односторонний,</li>
                    <li>двусторонний,</li>
                    <li>каучуковый,</li>
                    <li>акриловый.</li>
                </ul>
                <p style={mystyle}>
                    Первые два вида предполагают нанесение клея с одной или с обеих сторон основы. Большая часть
                    технического скотча является двусторонним, так как направлена на соединение параллельных плоскостей.
                </p>
                <p style={mystyle}>
                    Наиболее долгосрочный и стойкий к различным внешним факторам является синтетический акриловый клей.
                    Его применяют для суперпрочных клейких лент в строительстве, сантехнике, полиграфии и фотографии.
                    Каучуковая смесь отлично склеивает бумажные поверхности и является недорогой.
                </p>
                <p>
                    <strong>
                        В зависимости от структуры и сферы использования специалисты выделяют следующие виды клейкой
                        ленты:
                    </strong>
                </p>
                <ol>
                    <li>Канцелярская (полиэтиленовая),</li>
                    <li>Упаковочная,</li>
                    <li>Медицинская,</li>
                    <li>Армированная,</li>
                    <li>Алюминиевая,</li>
                    <li>На вспененной основе,</li>
                    <li>Малярная (крепп).</li>
                </ol>
                <p style={mystyle}>
                    Самый распространенный скотч &ndash; канцелярский. Он продается во всех магазинах и отличается
                    минимальной прочностью и низкой ценой.
                </p>
                <p>
                    <strong>
                        Вторым по популярности видом выступает упаковочный скотч, что может быть разных модификаций:
                    </strong>
                </p>
                <ul>
                    <li>прозрачный,</li>
                    <li>коричневый (в тон картонным коробкам),</li>
                    <li>цветной,</li>
                    <li>бредированный.</li>
                </ul>
                <p style={mystyle}>
                    Клейкие ленты всех вариантов используют для упаковки посылок и грузов на почтовых отделениях,
                    складских помещениях. Они обладают средней степенью адгезии и прочности. При ненадлежащем хранении
                    скотчи теряют свою липкость и способны отставать от поверхности. Поэтому посылки дополнительно
                    заворачивают в стрейч пленку.
                </p>
                <p style={mystyle}>
                    Брендированная клейкая лента с надписями &ndash; это инновационный продукт, что позволил на
                    полиэтиленовую ленту нанести разным способом простой или сложный рисунок. Он увеличивает
                    узнаваемость компании, защищает груз от намеренных повреждений и т.д.
                </p>
                <p style={mystyle}>
                    Малярная лента или креп является наименее прочной среди всех лент. Но при этом она широко
                    используется в строительстве, ремонте, малярных работах разного уровня сложности. Стоимость
                    минимальная. Ширина и длина различная. Малярный скотч &ndash; расходный материал.
                </p>
                <h3>Технический скотч</h3>
                <p style={mystyle}>К разряду узкопрофильных, специальных продуктов относят ленты следующих типов:</p>
                <ul>
                    <li>армированная,</li>
                    <li>алюминиевая,</li>
                    <li>на вспененной основе.</li>
                </ul>
                <p style={mystyle}>
                    Данные виды используются в экстремальных условиях, когда лента должна быть прочной, с высокой
                    адгезией, отлично переносить механические нагрузки, вибрацию, влагу, холод&nbsp; или жару.
                </p>
                <p style={mystyle}>
                    Например, армированный скотч укреплен специальными стекловолоконными нитями (поперек или
                    сеткообразно). Такая структура разработана для высоких механических нагрузок при упаковке грузов или
                    устранению проломов, щелей в воздухопроводах системы кондиционирования, трубах и т.д.
                </p>
                <p style={mystyle}>
                    Металл в комбинации с акриловым клеем обеспечивает сверхпрочное соединение поверхностей. Отлично
                    работает при ремонте и сборке техники и электроники.
                </p>
                <p style={mystyle}>
                    Все виды вышеперечисленного скотча можно заказать у компании SVETLA (Светла), что является прямым
                    дистрибьютором производителя. Таким образом, гарантируется выгодная цена, широкий ассортимент и
                    полный спектр возможностей по заказу количества продукции.
                </p>
            </div>
        </div>
    );

    return <React.Fragment>{content}</React.Fragment>;
}

export default HomePageArticle;
