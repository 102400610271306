// react
import React from "react";

// third-party
import { Helmet } from "react-helmet";

// blocks
import BlockBanner from "../blocks/BlockBanner";
import BlockBrands from "../blocks/BlockBrands";
import BlockCategories from "../blocks/BlockCategories";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockPosts from "../blocks/BlockPosts";
import BlockProductColumns from "../blocks/BlockProductColumns";
import BlockProducts from "../blocks/BlockProducts";
import BlockSlideShow from "../blocks/BlockSlideShow";
import BlockTabbedProductsCarousel from "../blocks/BlockTabbedProductsCarousel";
import HomePageShopBlock from "./HomePageShopBlock";
import HomePageArticle from "./HomePageArticle";

// data stubs
import categories from "../../data/shopBlockCategories";
import posts from "../../data/blogPosts";
import products from "../../data/shopProducts";
import theme from "../../data/theme";
import shopBestSellers from "../../data/shopBestSellers";
function HomePageOne() {
    const columns = [
        {
            title: "Top Rated Products",
            products: products.slice(0, 3),
        },
        {
            title: "Special Offers",
            products: products.slice(3, 6),
        },
        {
            title: "Bestsellers",
            products: products.slice(6, 9),
        },
    ];

    let shownBestsellers = [];

    for (let i = 0; i < shopBestSellers.length; i++) {
        for (let k = 0; k < products.length; k++) {
            if (shopBestSellers[i] == products[k].id) shownBestsellers.push(products[k]);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Строительные ленты — ${theme.name}`}</title>
            </Helmet>
            <BlockSlideShow withDepartments />
            <BlockFeatures />

            <BlockProducts
                title="Бестселлеры"
                layout="large-first"
                featuredProduct={products[0]}
                //products={products.slice(1, 7)}
                products={shownBestsellers}
            />

            <BlockTabbedProductsCarousel title="Новые поступления" layout="horizontal" rows={2} />
            <HomePageArticle />
            {/*
            <HomePageShopBlock />

            <BlockTabbedProductsCarousel title="Featured Products" layout="grid-4" />

            <BlockBanner />
            */}

            {/*
            <BlockCategories title="Popular Categories" layout="classic" categories={categories} />

            <BlockTabbedProductsCarousel title="New Arrivals" layout="horizontal" rows={2} />

            <BlockPosts title="Latest News" layout="list-sm" posts={posts} />

            <BlockBrands />

            <BlockProductColumns columns={columns} />
            */}

            <BlockPosts title="Свежее в блоге" layout="list-sm" posts={posts} />
        </React.Fragment>
    );
}

export default HomePageOne;
