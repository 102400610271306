// react
import React from "react";

// third-party
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// application
import SlickWithPreventSwipeClick from "../shared/SlickWithPreventSwipeClick";

// data stubs
import theme from "../../data/theme";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const mystyle = {
    textAlign: "justify",
};

function SitePageAboutUs() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`О нас — ${theme.name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/aboutus.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">О нас</h1>
                            <div className="about-us__text typography">
                                <p style={mystyle}>
                                    Компания SVETLA (Светла) уже более 5 лет успешно работает на рынке клейкой ленты и
                                    других упаковочных материалов Украины. С нашей помощью десятки предприятий по всей
                                    стране (Киеве, Львове, Житомире, Одессе и Харькове) пользуются продукцией
                                    отечественного производства европейского качества.
                                </p>
                                <h2>
                                    <br />У нас вы можете купить или заказать такие товары:
                                </h2>
                                <ol style={mystyle}>
                                    <li>Упаковочный скотч (прозрачный, коричневый).</li>
                                    <li>Стрейч пленка (прозрачная, черная).</li>
                                    <li>Армированную клейкую ленту (лента со стекловолокном).</li>
                                    <li>Металлическую клейкую ленту (алюминиевую).</li>
                                    <li>Тканевый скотч.</li>
                                    <li>Скотч на вспененной основе.</li>
                                    <li>
                                        Брендированную липкую ленту (цветную или прозрачную с рисунком, с логотипом).
                                    </li>
                                    <li>Малярную ленту.</li>
                                    <li>Все виды двустороннего скотча.</li>
                                </ol>
                                <h3>
                                    <br />
                                    При помощи различного скотча SVETLA осуществляются такие работы:
                                </h3>
                                <ul style={mystyle}>
                                    <li>ремонт бытовой техники и электроники,</li>
                                    <li>онтаж напольных и настенных покрытий,</li>
                                    <li>установка и ремонт воздухопроводов,</li>
                                    <li>ремонтные работы сантехнического направления,</li>
                                    <li>упаковка легких, средних и тяжелых грузов,</li>
                                    <li>создание фирменного стиля (скотч с рисунком или логотипом),</li>
                                    <li>маркировка техники, оборудования,</li>
                                    <li>разграничение рабочего пространства на производстве,</li>
                                    <li>малярные и покрасочные работы разного уровня и направления и т.д.</li>
                                </ul>
                                <h3>
                                    <br />
                                    Почему стоит работать с компаний SVETLA?
                                </h3>
                                <ol style={mystyle}>
                                    <li>Идеальная репутация на рынке полимерной клейкой ленты.</li>
                                    <li>Нас рекомендуют партнеры.</li>
                                    <li>Сотни позитивных отзывов от розничных и оптовых покупателей.</li>
                                    <li>Максимально полный ассортимент клейкой ленты и стрейч пленки.</li>
                                    <li>Профессиональный подход.</li>
                                </ol>
                                <h3>
                                    <br />
                                    Выгоды от SVETLA
                                </h3>
                                <ol style={mystyle}>
                                    <li>Профессиональная полная консультация.</li>
                                    <li>Индивидуальное обслуживание.</li>
                                    <li>Быстрая обработка заказов (оперативность).</li>
                                    <li>Мы всегда на связи.</li>
                                    <li>Возможно изготовление под заказ (ширина, длина, цвет, рисунок, количество).</li>
                                    <li>Конкурентная цена на продукцию.</li>
                                    <li>Система скидок и лояльности при постоянном и оптовом сотрудничестве.</li>
                                    <li>Доставка в любой регион Украины.</li>
                                    <li>
                                        Несколько способов оплаты (наличными, безналичный расчет, на карту, наложенным
                                        платежом).
                                    </li>
                                </ol>

                                <p>По вопросам сотрудничества обращайтесь: (063) 644 81 33</p>
                            </div>
                            {/*
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">Meat Our Team</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    Want to work in our friendly team?
                                    <br />
                                    <Link to="/site/contact-us">Contact us</Link> and we will consider your candidacy.
                                </div>
                                <div className="about-us__teammates teammates">
                                    <SlickWithPreventSwipeClick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Michael Russo</div>
                                            <div className="teammate__position text-muted">Chief Executive Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Katherine Miller</div>
                                            <div className="teammate__position text-muted">Marketing Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Anthony Harris</div>
                                            <div className="teammate__position text-muted">Finance Director</div>
                                        </div>
                                    </SlickWithPreventSwipeClick>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
