// react
import React from "react";

// third-party
import classNames from "classnames";

import { Link } from "react-router-dom";

// application
import BlogCommentsList from "./BlogCommentsList";

// data stubs
import comments from "../../data/blogPostComments";
import posts from "../../data/blogPosts";

const BlogPost = props => {
    const { post } = props;

    const relatedPostsList = posts.slice(0, 2).map(relatedPost => (
        <div key={relatedPost.id} className="related-posts__item post-card post-card--layout--related">
            <div className="post-card__image">
                <Link to="/">
                    <img src={relatedPost.image} alt="" />
                </Link>
            </div>
            <div className="post-card__info">
                <div className="post-card__name">
                    <Link to="/">{relatedPost.title}</Link>
                </div>
                <div className="post-card__date">{relatedPost.date}</div>
            </div>
        </div>
    ));

    //let text = <div>{post.text}</div>;

    return (
        <div className={`block post post--layout--classic`}>
            <div className={`post__header post-header post-header--layout--classic`}>
                {/*}
                <div className="post-header__categories">
                    <Link to="/">Тематические публикации</Link>
                </div>
                */}
                <h1 className="post-header__title">{post.title}</h1>
                <div className="post-header__meta">
                    {/*
                    <div className="post-header__meta-item">
                        By
                        <Link to="/">Jessica Moore</Link>
                    </div>
                    */}
                    <div className="post-header__meta-item">{post.category}</div>
                    <div className="post-header__meta-item">{post.date}</div>
                    {/*
                    <div className="post-header__meta-item">
                        <Link to="/">4 Comments</Link>
                    </div>
                    */}
                </div>
            </div>

            <div className="post__featured">
                <img src={post.image} alt={post.title} />
            </div>

            <div className="post__content typography">
                <div dangerouslySetInnerHTML={{ __html: post.text }} />
            </div>

            {/*
            <div className="post__footer">
                <div className="post__tags-share-links">
                    <div className="post__tags tags">
                        <div className="tags__list">
                            <Link to="/">Promotion</Link>
                            <Link to="/">Power Tool</Link>
                            <Link to="/">Wrench</Link>
                            <Link to="/">Electrodes</Link>
                        </div>
                    </div>
                    <div className="post__share-links share-links">
                        <ul className="share-links__list">
                            <li className="share-links__item share-links__item--type--like">
                                <Link to="/">Like</Link>
                            </li>
                            <li className="share-links__item share-links__item--type--tweet">
                                <Link to="/">Tweet</Link>
                            </li>
                            <li className="share-links__item share-links__item--type--pin">
                                <Link to="/">Pin It</Link>
                            </li>
                            <li className="share-links__item share-links__item--type--counter">
                                <Link to="/">4K</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="post-author">
                    <div className="post-author__avatar">
                        <Link to="/">
                            <img src="images/avatars/avatar-1.jpg" alt="" />
                        </Link>
                    </div>
                    <div className="post-author__info">
                        <div className="post-author__name">
                            <Link to="/">Jessica Moore</Link>
                        </div>
                        <div className="post-author__about">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit suscipit mi, non
                            tempor nulla finibus eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </div>
                    </div>
                </div>
            </div>
            <section className="post__section">
                <h4 className="post__section-title">Related Posts</h4>
                <div className="related-posts">
                    <div className="related-posts__list">{relatedPostsList}</div>
                </div>
            </section>

            <section className="post__section">
                <h4 className="post__section-title">{`Comments (${comments.count})`}</h4>

                <BlogCommentsList comments={comments.items} />
            </section>

            <section className="post__section">
                <h4 className="post__section-title">Write A Comment</h4>

                <form>
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="comment-first-name">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="comment-first-name"
                                placeholder="First Name"
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="comment-last-name">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="comment-last-name"
                                placeholder="Last Name"
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="comment-email">Email Address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="comment-email"
                                placeholder="Email Address"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="comment-content">Comment</label>
                        <textarea className="form-control" id="comment-content" rows="6" />
                    </div>
                    <div className="form-group mt-4">
                        <button type="submit" className="btn btn-primary btn-lg">
                            Post Comment
                        </button>
                    </div>
                </form>
            </section>
            */}
        </div>
    );
};

export default BlogPost;
