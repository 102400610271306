// react
import React from "react";
import { useHistory } from "react-router-dom";
// third-party
import classNames from "classnames";

// application
import { ArrowRoundedLeft8x13Svg, ArrowRoundedRight8x13Svg } from "../../svg";

const Pagination = (props) => {
    let history = useHistory();
    function setPage(page) {
        //console.log(page);
        history.push(`/blog/latestnews/?page=${page}`);
    }

    const { current, total, siblings } = props;

    console.log(current, total, siblings);

    const firstLinkClasses = classNames("page-item", {
        disabled: current <= 1,
    });
    const lastLinkClasses = classNames("page-item", {
        disabled: current >= total,
    });

    function getPages() {
        const pages = [];
        const min = Math.max(1, current - siblings - Math.max(0, siblings - total + current));
        const max = Math.min(total, min + siblings * 2);

        for (let i = min; i <= max; i += 1) {
            pages.push(i);
        }
        console.log(pages);
        return pages;
    }

    const pages = getPages().map((page, index) => {
        const classes = classNames("page-item", {
            active: page == current,
        });

        return (
            <li key={index} className={classes}>
                <button type="button" className="page-link" onClick={() => setPage(page)}>
                    {page}
                    {page === current && <span className="sr-only">(current)</span>}
                </button>
            </li>
        );
    });

    return (
        <ul className="pagination justify-content-center">
            <li className={firstLinkClasses}>
                <button
                    type="button"
                    className="page-link page-link--with-arrow"
                    aria-label="Previous"
                    onClick={() => setPage(+current - 1)}
                >
                    <ArrowRoundedLeft8x13Svg className="page-link__arrow page-link__arrow--left" aria-hidden="true" />
                </button>
            </li>
            {pages}
            <li className={lastLinkClasses}>
                <button
                    type="button"
                    className="page-link page-link--with-arrow"
                    aria-label="Next"
                    onClick={() => setPage(+current + 1)}
                >
                    <ArrowRoundedRight8x13Svg className="page-link__arrow page-link__arrow--right" aria-hidden="true" />
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
