// react
import React from "react";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterNewsletter from "./FooterNewsletter";

// data stubs
import theme from "../../data/theme";

export default function Footer() {
    const informationLinks = [
        { title: "Главная", url: "/" },
        { title: "О нас", url: "/site/about-us" },
        //  { title: "Информация о доставке", url: "" },
        //  { title: "Политика конфиденциальности", url: "" },
        { title: "Контакты", url: "/site/contact-us" },
        //  { title: "Карта сайта", url: "" }
    ];

    const accountLinks = [
        { title: "Store Location", url: "" },
        { title: "Order History", url: "" },
        { title: "Wish List", url: "" },
        { title: "Newsletter", url: "" },
        { title: "Specials", url: "" },
        { title: "Gift Certificates", url: "" },
        { title: "Affiliate", url: "" },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-4 col-lg-3">
                            <FooterLinks title="Информация" items={informationLinks} />
                        </div>
                        {/*
                        <div className="col-12 col-md-3 col-lg-2">
                            <FooterLinks title="Мой профиль" items={accountLinks} />
                        </div>
                        */}
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Developed by{" "}
                        <a href="http://ori.solutions/" rel="noopener noreferrer" target="_blank">
                            ORI Solutions
                        </a>
                    </div>
                    {/*
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
}
