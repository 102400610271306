// react
import React from "react";

// data stubs
import theme from "../../data/theme";

function WidgetAboutus() {
    const links = [
        {
            key: "facebook",
            url: "https://www.facebook.com/Svetlazt/",
            iconClass: "fab fa-facebook-f"
        },
        {
            key: "instagram",
            url: "https://www.instagram.com/svetlazt/",
            iconClass: "fab fa-instagram"
        },
        {
            /*
        {
            key: "rss",
            url: "",
            iconClass: "fas fa-rss"
        },
        {
            key: "youtube",
            url: "",
            iconClass: "fab fa-youtube"
        },
        
        {
            key: "twitter",
            url: "",
            iconClass: "fab fa-twitter"
        }
    */
        }
    ].map(item => {
        const itemClasses = `widget-aboutus__link widget-aboutus__link--${item.key}`;
        const iconClasses = `widget-social__icon ${item.iconClass}`;

        return (
            <li key={item.key}>
                <a className={itemClasses} href={item.url} target=" _blank">
                    <i className={iconClasses} />
                </a>
            </li>
        );
    });

    return (
        <div className="widget-aboutus widget">
            <h4 className="widget__title">О блоге</h4>
            <div className="widget-aboutus__text">
                Акции, новинки, актуальная информация всегда доступна для вас в нашем блоге!
            </div>
            <div className="widget-aboutus__socials">
                <ul>{links}</ul>
            </div>
        </div>
    );
}

export default WidgetAboutus;
