// react
import React from "react";
import { useParams } from "react-router";
// third-party
import { Helmet } from "react-helmet";

// application
import PageHeader from "../shared/PageHeader";
import BlogPost from "./BlogPost";
import BlogSidebar from "./BlogSidebar";

// data stubs
import theme from "../../data/theme";
import posts from "../../data/blogPosts";

const BlogPagePost = props => {
    let { id } = useParams();

    //Визначаємо пост за адресою
    let post;
    if (id) {
        post = posts.find(x => x.id === parseFloat(id));
    } else {
        post = posts[posts.length - 1];
    }

    if (post === undefined) {
        props.history.push("/404");
        return 0;
    }

    let content;

    const sidebar = <BlogSidebar />;
    let sidebarEnd = <div className="col-12 col-lg-4">{sidebar}</div>;

    content = (
        <div className="row">
            <div className="col-12 col-lg-8">
                <BlogPost post={post} />
            </div>
            {sidebarEnd}
        </div>
    );

    const breadcrumbs = [
        { title: "Главная", url: "/" },
        { title: "Блог", url: "/blog" },
        { title: post.title, url: "" }
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Blog Post Page — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumbs} />

            <div className="container">{content}</div>
        </React.Fragment>
    );
};
export default BlogPagePost;
