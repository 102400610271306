// react
import React from "react";
import { useParams } from "react-router";
// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// application
import { ArrowRoundedLeft6x9Svg } from "../../svg";
import categories from "../../data/shopCategories";
import products from "../../data/shopProducts";

function itemsCountInCategory(categoryID) {
    return products.filter(item => item.category == categoryID).length;
}

function FilterCategories(props) {
    let { id } = useParams();

    const categoriesList = categories.map(category => {
        let arrow = <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />;

        {
            /*
        if (category.type === 'parent') {
            arrow = <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />;
        }
        */
        }
        if (category.id == id) category.type = "current";
        else category.type = "parent";
        return (
            <li key={category.id} className={`filter-categories__item filter-categories__item--${category.type}`}>
                {arrow}
                <Link to={`/shop/category/${category.id}`}>{category.name}</Link>
                <div className="filter-categories__counter">{itemsCountInCategory(category.id)}</div>
            </li>
        );
    });

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">{categoriesList}</ul>
        </div>
    );
}

FilterCategories.propTypes = {
    categories: PropTypes.array
};

export default FilterCategories;
