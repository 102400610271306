// Example:
/*{ 
    id: 1,
    name: "Клейкая лента прозрачная",
    category: 105,
    price: 749,
    compareAtPrice: 800,
    images: ["images/products/prozora.jpg", "images/products/prozora2.jpg"],
    badges: ["new"], //new, sale, hot
    rating: 4,
    reviews: 12,
    availability: true,
    shortDescription: "Прозрачная клейкая лента - скотч. Толщина 40 микрон",
    description:
        "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
    features: [
        { name: "Толщина", value: "40мкм" },
        { name: "Упаковка", value: "Рукав" },
    ],
    options: [],
    sizes: [
        "24мм / 25м",
        "36мм / 25м",
        "45мм / 15м",
        "45мм / 25м",
        "45мм / 50м",
        "45мм / 100м",
        "45мм / 150м",
        "45мм / 250м",
        "45мм / 450м",
        "40мм / 50м",
    ],
    specification: [
        {
            name: "",
            features: [
                { name: "Толщина", value: "40мкм" },
                { name: "Упаковка", value: "Рукав" },
            ],
        },
        {
            name: "Возможные варианты по ширине рулона",
            features: [
                { name: "24мм", value: "25м" },
                { name: "36мм", value: "25м" },
                { name: "45мм", value: "15м" },
                { name: "45мм", value: "25м" },
                { name: "45мм", value: "50м" },
                { name: "45мм", value: "100м" },
                { name: "45мм", value: "150м" },
                { name: "45мм", value: "250м" },
                { name: "45мм", value: "450м" },
                { name: "72мм", value: "50м" },
            ],
        },
    ],
},
*/
export default [
    // 101 ---------------------------------------------------
    {
        id: 17867,
        name: "Алюминиевая клейкая лента 45*4м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17867.jpg",
            "images/products/alu/17867x1.jpg",
            "images/products/alu/17867x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17868,
        name: "Алюминиевая клейкая лента 45*8м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17868.jpg",
            "images/products/alu/17868x1.jpg",
            "images/products/alu/17868x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "8м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17869,
        name: "Алюминиевая клейкая лента 45*20м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17869.jpg",
            "images/products/alu/17869x1.jpg",
            "images/products/alu/17869x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "20м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17870,
        name: "Алюминиевая клейкая лента 45*40м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17870.jpg",
            "images/products/alu/17870x1.jpg",
            "images/products/alu/17870x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17871,
        name: "Алюминиевая клейкая лента 72*8м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17871.jpg",
            "images/products/alu/17871x1.jpg",
            "images/products/alu/17871x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "8м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17872,
        name: "Алюминиевая клейкая лента 72*20м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17872.jpg",
            "images/products/alu/17872x1.jpg",
            "images/products/alu/17872x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "20м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17873,
        name: "Алюминиевая клейкая лента 72*40м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17873.jpg",
            "images/products/alu/17873x1.jpg",
            "images/products/alu/17873x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17874,
        name: "Алюминиевая клейкая лента 100*20м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17874.jpg",
            "images/products/alu/17874x1.jpg",
            "images/products/alu/17874x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "100мм" },
            { name: "Длина", value: "20м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17862,
        name: "Алюминиевая клейкая лента 36*8м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17862.jpg",
            "images/products/alu/17862x1.jpg",
            "images/products/alu/17862x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "36мм" },
            { name: "Длина", value: "8м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17863,
        name: "Алюминиевая клейкая лента 45*4м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17863.jpg",
            "images/products/alu/17863x1.jpg",
            "images/products/alu/17863x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17864,
        name: "Алюминиевая клейкая лента 45*8м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17864.jpg",
            "images/products/alu/17864x1.jpg",
            "images/products/alu/17864x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "8м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17865,
        name: "Алюминиевая клейкая лента 45*20м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17865.jpg",
            "images/products/alu/17865x1.jpg",
            "images/products/alu/17865x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "20м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17866,
        name: "Алюминиевая клейкая лента 45*40м SVETLA",
        category: 101,

        images: [
            "images/products/alu/17866.jpg",
            "images/products/alu/17866x1.jpg",
            "images/products/alu/17866x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Монтажная клейкая лента алюминиевая",
        description:
            "Используется для уплотнения вентиляционных труб, резиновых шлангов, укрывных пленок. Влагоустойчивость и пыленепроницаемость. Отрывается вручную. Высокая прочность на разрыв. Низкий коэффициент удлинения.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    //      /101    --------------------------------------------------------

    //      102     --------------------------------------------------------
    {
        id: 17902,
        name: "Двусторонняя клейкая лента на бумажной основе 9*5м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17902.jpg",
            "images/products/dvostor/17902x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "9мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17903,
        name: "Двусторонняя клейкая лента на бумажной основе 12*5м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17903.jpg",
            "images/products/dvostor/17903x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "12мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17904,
        name: "Двусторонняя клейкая лента на бумажной основе 18*5м SVETLA",
        category: 102,

        images: ["images/products/dvostor/17904.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "18мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17905,
        name: "Двусторонняя клейкая лента на бумажной основе 24*5м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17905.jpg",
            "images/products/dvostor/17905x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17906,
        name: "Двусторонняя клейкая лента на бумажной основе 36*5м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17906.jpg",
            "images/products/dvostor/17906x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "36мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17907,
        name: "Двусторонняя клейкая лента на бумажной основе 45*5м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17907.jpg",
            "images/products/dvostor/17907x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17908,
        name: "Двусторонняя клейкая лента на бумажной основе 45*10м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17908.jpg",
            "images/products/dvostor/17908x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги, тонких пластиков, деревянных деталей",
        description:
            "Двусторонняя клейкая лента на бумажной основе отлично подходит для склеивания картона, бумаги с ламинацией и без, с защитным лаком, тонких пластиков, деревянных деталей, металлических деталей. Применяется для сплаинга (сращивания) бумаг, тонких картонов, пленок и т.п., для изготовления полиграфической продукции, для крепления шелфтокеров, ценников к полкам, плакатов, муляжей и др.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "5м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17875,
        name:
            "Двусторонняя клейкая лента на полипропиленовой основе 24*4м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17875.jpg",
            "images/products/dvostor/17875x1.jpg",
            "images/products/dvostor/17875x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на полипропиленовой основе",
        description: "Двусторонняя клейкая лента на полипропиленовой основе",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17876,
        name:
            "Двусторонняя клейкая лента на полипропиленовой основе 36*4м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17876.jpg",
            "images/products/dvostor/17876x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на полипропиленовой основе",
        description: "Двусторонняя клейкая лента на полипропиленовой основе",
        features: [
            { name: "Ширина", value: "36мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17877,
        name:
            "Двусторонняя клейкая лента на полипропиленовой основе 45*4м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17877.jpg",
            "images/products/dvostor/17877x1.jpg",
            "images/products/dvostor/17877x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на полипропиленовой основе",
        description: "Двусторонняя клейкая лента на полипропиленовой основе",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17878,
        name:
            "Двусторонняя клейкая лента на полипропиленовой основе 45*8м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17878.jpg",
            "images/products/dvostor/17878x1.jpg",
            "images/products/dvostor/17878x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на полипропиленовой основе",
        description: "Двусторонняя клейкая лента на полипропиленовой основе",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "8м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17880,
        name:
            "Двусторонняя клейкая лента на полипропиленовой основе 45*40м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17880.jpg",
            "images/products/dvostor/17880x1.jpg",
            "images/products/dvostor/17880x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента на полипропиленовой основе",
        description: "Двусторонняя клейкая лента на полипропиленовой основе",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17881,
        name: "Двусторонняя клейкая лента на тканевой основе 24*4м SVETLA",
        category: 102,

        images: ["images/products/dvostor/17881.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя клейкая повышенной прочности на тканевой основе",
        description:
            "Лента применяется в строительной отрасли и в быту. Используется для временных и постоянных креплений на шероховатых и неровных поверхностях. Рекомендуется для укладки коврового покрытия, линолеума и других покрытий для пола, приклеивания пластиковых коробов, временной фиксации материалов на оборудовании. Благодаря тканевой основе клейкая лента обладает повышенной прочностью и устойчива к износу.",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17882,
        name: "Двусторонняя клейкая лента на тканевой основе 36*4м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17882.jpg",
            "images/products/dvostor/17882x1.jpg",
            "images/products/dvostor/17882x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя клейкая повышенной прочности на тканевой основе",
        description:
            "Лента применяется в строительной отрасли и в быту. Используется для временных и постоянных креплений на шероховатых и неровных поверхностях. Рекомендуется для укладки коврового покрытия, линолеума и других покрытий для пола, приклеивания пластиковых коробов, временной фиксации материалов на оборудовании. Благодаря тканевой основе клейкая лента обладает повышенной прочностью и устойчива к износу.",
        features: [
            { name: "Ширина", value: "36мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17883,
        name: "Двусторонняя клейкая лента на тканевой основе 45*4м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17883.jpg",
            "images/products/dvostor/17883x1.jpg",
            "images/products/dvostor/17883x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя клейкая повышенной прочности на тканевой основе",
        description:
            "Лента применяется в строительной отрасли и в быту. Используется для временных и постоянных креплений на шероховатых и неровных поверхностях. Рекомендуется для укладки коврового покрытия, линолеума и других покрытий для пола, приклеивания пластиковых коробов, временной фиксации материалов на оборудовании. Благодаря тканевой основе клейкая лента обладает повышенной прочностью и устойчива к износу.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17884,
        name: "Двусторонняя клейкая лента на тканевой основе 45*8м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17884.jpg",
            "images/products/dvostor/17884x1.jpg",
            "images/products/dvostor/17884x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя клейкая повышенной прочности на тканевой основе",
        description:
            "Лента применяется в строительной отрасли и в быту. Используется для временных и постоянных креплений на шероховатых и неровных поверхностях. Рекомендуется для укладки коврового покрытия, линолеума и других покрытий для пола, приклеивания пластиковых коробов, временной фиксации материалов на оборудовании. Благодаря тканевой основе клейкая лента обладает повышенной прочностью и устойчива к износу.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "8м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17885,
        name: "Двусторонняя клейкая лента на тканевой основе 45*20м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17885.jpg",
            "images/products/dvostor/17885x1.jpg",
            "images/products/dvostor/17885x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя клейкая повышенной прочности на тканевой основе",
        description:
            "Лента применяется в строительной отрасли и в быту. Используется для временных и постоянных креплений на шероховатых и неровных поверхностях. Рекомендуется для укладки коврового покрытия, линолеума и других покрытий для пола, приклеивания пластиковых коробов, временной фиксации материалов на оборудовании. Благодаря тканевой основе клейкая лента обладает повышенной прочностью и устойчива к износу.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "20м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17886,
        name: "Двусторонняя клейкая лента на тканевой основе 45*40м SVETLA",
        category: 102,

        images: [
            "images/products/dvostor/17886.jpg",
            "images/products/dvostor/17886x1.jpg",
            "images/products/dvostor/17886x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя клейкая повышенной прочности на тканевой основе",
        description:
            "Лента применяется в строительной отрасли и в быту. Используется для временных и постоянных креплений на шероховатых и неровных поверхностях. Рекомендуется для укладки коврового покрытия, линолеума и других покрытий для пола, приклеивания пластиковых коробов, временной фиксации материалов на оборудовании. Благодаря тканевой основе клейкая лента обладает повышенной прочностью и устойчива к износу.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    //      /102     --------------------------------------------------------
    //      103     --------------------------------------------------------
    {
        id: 17887,
        name:
            "Двусторонняя вспененная лента (черная с зеленым лайнером) 12*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17887.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная (черная с зеленым лайнером)",
        description:
            "Черная двусторонняя вспененная лента применяется для монтажа зеркал, крепления ветровиков, мухобоек, при производстве стеклопакетов. Устойчива к воздействию внешней среды и вибраций. Отлично клеит картон, дерево, металл, стекло, пластик.",
        features: [
            { name: "Ширина", value: "12мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17888,
        name:
            "Двусторонняя вспененная лента (черная с зеленым лайнером) 15*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17888.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная (черная с зеленым лайнером)",
        description:
            "Черная двусторонняя вспененная лента применяется для монтажа зеркал, крепления ветровиков, мухобоек, при производстве стеклопакетов. Устойчива к воздействию внешней среды и вибраций. Отлично клеит картон, дерево, металл, стекло, пластик.",
        features: [
            { name: "Ширина", value: "15мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17889,
        name:
            "Двусторонняя вспененная лента (черная с зеленым лайнером) 18*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17889.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная (черная с зеленым лайнером)",
        description:
            "Черная двусторонняя вспененная лента применяется для монтажа зеркал, крепления ветровиков, мухобоек, при производстве стеклопакетов. Устойчива к воздействию внешней среды и вибраций. Отлично клеит картон, дерево, металл, стекло, пластик.",
        features: [
            { name: "Ширина", value: "18мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17890,
        name:
            "Двусторонняя вспененная лента (черная с зеленым лайнером) 21*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17890.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная (черная с зеленым лайнером)",
        description:
            "Черная двусторонняя вспененная лента применяется для монтажа зеркал, крепления ветровиков, мухобоек, при производстве стеклопакетов. Устойчива к воздействию внешней среды и вибраций. Отлично клеит картон, дерево, металл, стекло, пластик.",
        features: [
            { name: "Ширина", value: "21мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17891,
        name:
            "Двусторонняя вспененная лента (черная с зеленым лайнером) 24*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17891.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная (черная с зеленым лайнером)",
        description:
            "Черная двусторонняя вспененная лента применяется для монтажа зеркал, крепления ветровиков, мухобоек, при производстве стеклопакетов. Устойчива к воздействию внешней среды и вибраций. Отлично клеит картон, дерево, металл, стекло, пластик.",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17897,
        name:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером) 12*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17897.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером)",
        description:
            "Лента применяется для монтажа зеркал, для склеивания картона, дерева, для монтажа плакатов и других рекламных материалов, легких табличек, оформления и выполнения творческих работ.",
        features: [
            { name: "Ширина", value: "12мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17898,
        name:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером) 15*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17898.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером)",
        description:
            "Лента применяется для монтажа зеркал, для склеивания картона, дерева, для монтажа плакатов и других рекламных материалов, легких табличек, оформления и выполнения творческих работ.",
        features: [
            { name: "Ширина", value: "15мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17899,
        name:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером) 18*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17899.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером)",
        description:
            "Лента применяется для монтажа зеркал, для склеивания картона, дерева, для монтажа плакатов и других рекламных материалов, легких табличек, оформления и выполнения творческих работ.",
        features: [
            { name: "Ширина", value: "18мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17900,
        name:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером) 21*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17900.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером)",
        description:
            "Лента применяется для монтажа зеркал, для склеивания картона, дерева, для монтажа плакатов и других рекламных материалов, легких табличек, оформления и выполнения творческих работ.",
        features: [
            { name: "Ширина", value: "21мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17901,
        name:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером) 24*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17901.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя вспененная лента специализированная (белая с белым лайнером)",
        description:
            "Лента применяется для монтажа зеркал, для склеивания картона, дерева, для монтажа плакатов и других рекламных материалов, легких табличек, оформления и выполнения творческих работ.",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 178892,
        name:
            "Двусторонняя вспененная лента специализированная (белая с красным лайнером) 12*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17892.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная специализированная, выдерживает сложные погодные условия, устойчива к ультрафиолету",
        description:
            "Лента двусторонняя вспененная специализированная применяется для монтажа зеркал, табличек, рекламных материалов, легких букв, вывесок как внутри помещения так и на улице, отлично клеит картон, дерево, металл, стекло, пластик, выдерживает сложные погодные условия, устойчива к ультрафиолету.",
        features: [
            { name: "Ширина", value: "12мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 178893,
        name:
            "Двусторонняя вспененная лента специализированная (белая с красным лайнером) 15*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17893.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная специализированная, выдерживает сложные погодные условия, устойчива к ультрафиолету",
        description:
            "Лента двусторонняя вспененная специализированная применяется для монтажа зеркал, табличек, рекламных материалов, легких букв, вывесок как внутри помещения так и на улице, отлично клеит картон, дерево, металл, стекло, пластик, выдерживает сложные погодные условия, устойчива к ультрафиолету.",
        features: [
            { name: "Ширина", value: "15мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 178894,
        name:
            "Двусторонняя вспененная лента специализированная (белая с красным лайнером) 18*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17894.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная специализированная, выдерживает сложные погодные условия, устойчива к ультрафиолету",
        description:
            "Лента двусторонняя вспененная специализированная применяется для монтажа зеркал, табличек, рекламных материалов, легких букв, вывесок как внутри помещения так и на улице, отлично клеит картон, дерево, металл, стекло, пластик, выдерживает сложные погодные условия, устойчива к ультрафиолету.",
        features: [
            { name: "Ширина", value: "18мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 178895,
        name:
            "Двусторонняя вспененная лента специализированная (белая с красным лайнером) 21*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17895.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная специализированная, выдерживает сложные погодные условия, устойчива к ультрафиолету",
        description:
            "Лента двусторонняя вспененная специализированная применяется для монтажа зеркал, табличек, рекламных материалов, легких букв, вывесок как внутри помещения так и на улице, отлично клеит картон, дерево, металл, стекло, пластик, выдерживает сложные погодные условия, устойчива к ультрафиолету.",
        features: [
            { name: "Ширина", value: "21мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17896,
        name:
            "Двусторонняя вспененная лента специализированная (белая с красным лайнером) 24*4м SVETLA",
        category: 103,

        images: ["images/products/vspen/17896.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Лента двусторонняя вспененная специализированная, выдерживает сложные погодные условия, устойчива к ультрафиолету",
        description:
            "Лента двусторонняя вспененная специализированная применяется для монтажа зеркал, табличек, рекламных материалов, легких букв, вывесок как внутри помещения так и на улице, отлично клеит картон, дерево, металл, стекло, пластик, выдерживает сложные погодные условия, устойчива к ультрафиолету.",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "4м" },
        ],
        options: [],
        sizes: [],
    },
    //      /103     --------------------------------------------------------
    //      104     --------------------------------------------------------
    {
        id: 17909,
        name: "Двусторонняя супер клейкая лента пеноакрил VHB 6*3м SVETLA",
        category: 104,

        images: [
            "images/products/vhb/17909.jpg",
            "images/products/vhb/17909x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента пеноакрил VHB супер прочная, устойчива к большинству видов негативного воздействия. Замена шурупам, саморезам и гвоздям",
        description:
            "Лента состоит по всей толщине из вспененного акрилового полимера с закрытыми ячейками. Структура ленты VHB поглощает нагрузки на разрыв, сдвиг, расщепление и расслоение. Вязкая и эластичная структура клея позволяет проникать в микропоры склеиваемых поверхностей. Обеспечивает высокую адгезию склеиваемых поверхностей, прочное и долговременное соединение. Снимает напряжение вызванное расширением и сужением материалов. Устойчива к воздействию погодных условий, температур, влаги, ультрафиолета, химических веществ. Поглощает вибрации. Соединяет разнородные материалы. Замещает сверления, сварки, делает дизайн привлекательным. Делает соединение герметичным, изолирует разнородные материалы для предотвращения коррозии, изолирует шум и вибрации, снижает вес по сравнению с механическим креплением.",
        features: [
            { name: "Ширина", value: "6мм" },
            { name: "Длина", value: "3м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17910,
        name: "Двусторонняя супер клейкая лента пеноакрил VHB 12*3м SVETLA",
        category: 104,

        images: [
            "images/products/vhb/17910.jpg",
            "images/products/vhb/17910x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента пеноакрил VHB супер прочная, устойчива к большинству видов негативного воздействия. Замена шурупам, саморезам и гвоздям",
        description:
            "Лента состоит по всей толщине из вспененного акрилового полимера с закрытыми ячейками. Структура ленты VHB поглощает нагрузки на разрыв, сдвиг, расщепление и расслоение. Вязкая и эластичная структура клея позволяет проникать в микропоры склеиваемых поверхностей. Обеспечивает высокую адгезию склеиваемых поверхностей, прочное и долговременное соединение. Снимает напряжение вызванное расширением и сужением материалов. Устойчива к воздействию погодных условий, температур, влаги, ультрафиолета, химических веществ. Поглощает вибрации. Соединяет разнородные материалы. Замещает сверления, сварки, делает дизайн привлекательным. Делает соединение герметичным, изолирует разнородные материалы для предотвращения коррозии, изолирует шум и вибрации, снижает вес по сравнению с механическим креплением.",
        features: [
            { name: "Ширина", value: "12мм" },
            { name: "Длина", value: "3м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17911,
        name: "Двусторонняя супер клейкая лента пеноакрил VHB 15*3м SVETLA",
        category: 104,

        images: [
            "images/products/vhb/17911.jpg",
            "images/products/vhb/17911x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента пеноакрил VHB супер прочная, устойчива к большинству видов негативного воздействия. Замена шурупам, саморезам и гвоздям",
        description:
            "Лента состоит по всей толщине из вспененного акрилового полимера с закрытыми ячейками. Структура ленты VHB поглощает нагрузки на разрыв, сдвиг, расщепление и расслоение. Вязкая и эластичная структура клея позволяет проникать в микропоры склеиваемых поверхностей. Обеспечивает высокую адгезию склеиваемых поверхностей, прочное и долговременное соединение. Снимает напряжение вызванное расширением и сужением материалов. Устойчива к воздействию погодных условий, температур, влаги, ультрафиолета, химических веществ. Поглощает вибрации. Соединяет разнородные материалы. Замещает сверления, сварки, делает дизайн привлекательным. Делает соединение герметичным, изолирует разнородные материалы для предотвращения коррозии, изолирует шум и вибрации, снижает вес по сравнению с механическим креплением.",
        features: [
            { name: "Ширина", value: "15мм" },
            { name: "Длина", value: "3м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17912,
        name: "Двусторонняя супер клейкая лента пеноакрил VHB 20*3м SVETLA",
        category: 104,

        images: [
            "images/products/vhb/17912.jpg",
            "images/products/vhb/17912x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента пеноакрил VHB супер прочная, устойчива к большинству видов негативного воздействия. Замена шурупам, саморезам и гвоздям",
        description:
            "Лента состоит по всей толщине из вспененного акрилового полимера с закрытыми ячейками. Структура ленты VHB поглощает нагрузки на разрыв, сдвиг, расщепление и расслоение. Вязкая и эластичная структура клея позволяет проникать в микропоры склеиваемых поверхностей. Обеспечивает высокую адгезию склеиваемых поверхностей, прочное и долговременное соединение. Снимает напряжение вызванное расширением и сужением материалов. Устойчива к воздействию погодных условий, температур, влаги, ультрафиолета, химических веществ. Поглощает вибрации. Соединяет разнородные материалы. Замещает сверления, сварки, делает дизайн привлекательным. Делает соединение герметичным, изолирует разнородные материалы для предотвращения коррозии, изолирует шум и вибрации, снижает вес по сравнению с механическим креплением.",
        features: [
            { name: "Ширина", value: "20мм" },
            { name: "Длина", value: "3м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17913,
        name: "Двусторонняя супер клейкая лента пеноакрил VHB 24*3м SVETLA",
        category: 104,

        images: [
            "images/products/vhb/17913.jpg",
            "images/products/vhb/17913x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Двусторонняя клейкая лента пеноакрил VHB супер прочная, устойчива к большинству видов негативного воздействия. Замена шурупам, саморезам и гвоздям",
        description:
            "Лента состоит по всей толщине из вспененного акрилового полимера с закрытыми ячейками. Структура ленты VHB поглощает нагрузки на разрыв, сдвиг, расщепление и расслоение. Вязкая и эластичная структура клея позволяет проникать в микропоры склеиваемых поверхностей. Обеспечивает высокую адгезию склеиваемых поверхностей, прочное и долговременное соединение. Снимает напряжение вызванное расширением и сужением материалов. Устойчива к воздействию погодных условий, температур, влаги, ультрафиолета, химических веществ. Поглощает вибрации. Соединяет разнородные материалы. Замещает сверления, сварки, делает дизайн привлекательным. Делает соединение герметичным, изолирует разнородные материалы для предотвращения коррозии, изолирует шум и вибрации, снижает вес по сравнению с механическим креплением.",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "3м" },
        ],
        options: [],
        sizes: [],
    },
    //      /104     --------------------------------------------------------
    //      105     --------------------------------------------------------
    {
        id: 17858,
        name: "Клейкая лента красная 48*40м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17858.jpg",
            "images/products/transpandcolor/17858x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента красная",
        description:
            "Цветная клейкая лента используется для упаковки коробок, маркировки корешков рабочих папок, книг в обложках, флаконов с лекарствами и т.д., выполнения творческих работ, запечатывания конвертов, оформления подарков.",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17861,
        name: "Клейкая лента желтая 48*40м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17861.jpg",
            "images/products/transpandcolor/17861x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента желтая",
        description:
            "Цветная клейкая лента используется для упаковки коробок, маркировки корешков рабочих папок, книг в обложках, флаконов с лекарствами и т.д., выполнения творческих работ, запечатывания конвертов, оформления подарков.",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17859,
        name: "Клейкая лента зеленая 48*40м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17859.jpg",
            "images/products/transpandcolor/17859x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента зеленая",
        description:
            "Цветная клейкая лента используется для упаковки коробок, маркировки корешков рабочих папок, книг в обложках, флаконов с лекарствами и т.д., выполнения творческих работ, запечатывания конвертов, оформления подарков.",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17860,
        name: "Клейкая лента синяя 48*40м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17860.jpg",
            "images/products/transpandcolor/17860x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента синяя",
        description:
            "Цветная клейкая лента используется для упаковки коробок, маркировки корешков рабочих папок, книг в обложках, флаконов с лекарствами и т.д., выполнения творческих работ, запечатывания конвертов, оформления подарков.",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "40м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17847,
        name: "Клейкая лента скотч 15м прозрачная 45*15м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17847.jpg",
            "images/products/transpandcolor/17847x1.jpg",
            "images/products/transpandcolor/17847x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17848,
        name: "Клейкая лента скотч 25м прозрачная 45*25м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17848.jpg",
            "images/products/transpandcolor/17848x1.jpg",
            "images/products/transpandcolor/17848x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "25м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17849,
        name: "Клейкая лента скотч 50м прозрачная 45*50м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17849.jpg",
            "images/products/transpandcolor/17849x1.jpg",
            "images/products/transpandcolor/17849x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "50м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17850,
        name: "Клейкая лента скотч 100м прозрачная 45*100м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17850.jpg",
            "images/products/transpandcolor/17850x1.jpg",
            "images/products/transpandcolor/17850x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "100м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17851,
        name: "Клейкая лента скотч 150м прозрачная 45*150м SVETLA",
        category: 105,

        images: ["images/products/transpandcolor/17851.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "150м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17852,
        name: "Клейкая лента скотч 250м прозрачная 45*250м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17852.jpg",
            "images/products/transpandcolor/17852x1.jpg",
            "images/products/transpandcolor/17852x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "250м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17853,
        name: "Клейкая лента скотч 450м прозрачная 45*450м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17853.jpg",
            "images/products/transpandcolor/17853x1.jpg",
            "images/products/transpandcolor/17853x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "450м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17845,
        name: "Клейкая лента скотч прозрачная 24*25м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17845.jpg",
            "images/products/transpandcolor/17845x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "25м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17846,
        name: "Клейкая лента скотч прозрачная 36*25м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17846.jpg",
            "images/products/transpandcolor/17846x1.jpg",
            "images/products/transpandcolor/17846x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "36мм" },
            { name: "Длина", value: "25м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17854,
        name: "Клейкая лента скотч прозрачная 72*50м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17854.jpg",
            "images/products/transpandcolor/17854x1.jpg",
            "images/products/transpandcolor/17854x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Прозрачная клейкая лента - скотч.",
        description:
            "Прозрачная клейкая лента предназначена для упаковки продукции, склеивания предметов, оформления и выполнения творческих работ",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "50м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17855,
        name:
            "Клейкая лента скотч прозрачная морозо- пыле стойкая (HotMelt) 45*150м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17855.jpg",
            "images/products/transpandcolor/17855x1.jpg",
            "images/products/transpandcolor/17855x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Морозо- пылестойкая клейкая лента для строительных и монтажных работ",
        description:
            "Ленту с клеевым слоем «Hot Melt» можно использовать в помещениях: с экстремальными температурными режимами от -20 до +50; с повышенной запылённостью; высокой влажностью (она менее чувствительна, чем лента с акриловым клеем к влажности окружающей среды)",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "150м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17856,
        name: "Клейкая лента скотч коричневая 50м 45*50м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17856.jpg",
            "images/products/transpandcolor/17856x1.jpg",
            "images/products/transpandcolor/17856x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Коричневая клейкая лента - скотч. Толщина 45 микрон",
        description:
            "Коричневая клейкая лента используется для упаковки коробок, маркировки корешков рабочих папок, книг в обложках, флаконов с лекарствами и т.д., выполнения творческих работ, запечатывания конвертов, оформления подарков.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "50м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17857,
        name: "Клейкая лента скотч коричневая 250м 45*250м SVETLA",
        category: 105,

        images: [
            "images/products/transpandcolor/17857.jpg",
            "images/products/transpandcolor/17857x1.jpg",
            "images/products/transpandcolor/17857x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Коричневая клейкая лента - скотч. Толщина 45 микрон",
        description:
            "Коричневая клейкая лента используется для упаковки коробок, маркировки корешков рабочих папок, книг в обложках, флаконов с лекарствами и т.д., выполнения творческих работ, запечатывания конвертов, оформления подарков.",
        features: [
            { name: "Ширина", value: "45мм" },
            { name: "Длина", value: "250м" },
        ],
        options: [],
        sizes: [],
    },
    //      /105     --------------------------------------------------------

    //      106     --------------------------------------------------------

    {
        id: 17927,
        name: "Клейкая лента 'Акция' 24*66м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17927.jpg",
            "images/products/spec/17927x1.jpg",
            "images/products/spec/17927x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента 'Акция'",
        description: "Клейкая лента 'Акция'",
        features: [
            { name: "Ширина", value: "24мм" },
            { name: "Длина", value: "66м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17928,
        name: "Клейкая лента 'Акция' 48*66м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17928.jpg",
            "images/products/spec/17928x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента 'Акция'",
        description: "Клейкая лента 'Акция'",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "66м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17931,
        name: "Клейкая лента 'Вышиванка' 48*66м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17931.jpg",
            "images/products/spec/17931x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента 'Вышиванка'",
        description: "Клейкая лента 'Вышиванка'",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "66м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17929,
        name: "Клейкая лента 'Осторожно стекло' 48*66м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17929.jpg",
            "images/products/spec/17929x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента 'Осторожно стекло'",
        description: "Клейкая лента 'Осторожно стекло'",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "66м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17930,
        name: "Клейкая лента 'Хрупкое' 48*66м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17930.jpg",
            "images/products/spec/17930x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента 'Хрупкое'",
        description: "Клейкая лента 'Хрупкое'",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "66м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17916,
        name: "Лента сигнальная с клейкой основой (красно-белая) 48*30м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17916.jpg",
            "images/products/spec/17916x1.jpg",
            "images/products/spec/17916x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Лента сигнальная с клейкой основой (красно-белая)",
        description: "Лента сигнальная с клейкой основой (красно-белая)",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "30м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17914,
        name: "Лента сигнальная (красно-белая) 72*100м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17914.jpg",
            "images/products/spec/17914x1.jpg",
            "images/products/spec/17914x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Лента сигнальная (красно-белая)",
        description: "Лента сигнальная (красно-белая)",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "100м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17915,
        name: "Лента сигнальная (красно-белая) 72*500м SVETLA",
        category: 106,

        images: [
            "images/products/spec/17915.jpg",
            "images/products/spec/17915x1.jpg",
            "images/products/spec/17915x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Лента сигнальная (красно-белая)",
        description: "Лента сигнальная (красно-белая)",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "500м" },
        ],
        options: [],
        sizes: [],
    },
    //      /106     --------------------------------------------------------
    //      107     --------------------------------------------------------

    {
        id: 18259,
        name: "Малярная клейкая лента желтая 19*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18259.jpg",
            "images/products/malyarn/18259x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "19мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 18260,
        name: "Малярная клейкая лента желтая 25*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18260.jpg",
            "images/products/malyarn/18260x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "25мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18261,
        name: "Малярная клейкая лента желтая 30*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18261.jpg",
            "images/products/malyarn/18261x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "30мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18262,
        name: "Малярная клейкая лента желтая 38*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18262.jpg",
            "images/products/malyarn/18262x1.jpg",
            "images/products/malyarn/18262x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "38мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18263,
        name: "Малярная клейкая лента желтая 38*30м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18263.jpg",
            "images/products/malyarn/18263x1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "38мм" },
            { name: "Длина", value: "30м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18264,
        name: "Малярная клейкая лента желтая 38*60м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18264.jpg",
            "images/products/malyarn/18264x1.jpg",
            "images/products/malyarn/18264x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "38мм" },
            { name: "Длина", value: "60м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18265,
        name: "Малярная клейкая лента желтая 48*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18265.jpg",
            "images/products/malyarn/18265x1.jpg",
            "images/products/malyarn/18265x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18266,
        name: "Малярная клейкая лента желтая 48*30м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18266.jpg",
            "images/products/malyarn/18266x1.jpg",
            "images/products/malyarn/18266x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "30м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18267,
        name: "Малярная клейкая лента желтая 48*60м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18267.jpg",
            "images/products/malyarn/18267x1.jpg",
            "images/products/malyarn/18267x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "48мм" },
            { name: "Длина", value: "60м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18268,
        name: "Малярная клейкая лента желтая 72*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18268.jpg",
            "images/products/malyarn/18268x1.jpg",
            "images/products/malyarn/18268x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "72мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 18269,
        name: "Малярная клейкая лента желтая 100*15м SVETLA",
        category: 107,

        images: [
            "images/products/malyarn/18269.jpg",
            "images/products/malyarn/18269x1.jpg",
            "images/products/malyarn/18269x2.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription: "Клейкая лента малярная желтая",
        description: "Клейкая лента малярная желтая",
        features: [
            { name: "Ширина", value: "100мм" },
            { name: "Длина", value: "15м" },
        ],
        options: [],
        sizes: [],
    },
    //      /107     --------------------------------------------------------

    //      108     --------------------------------------------------------
    {
        id: 17917,
        name:
            "Стрейч пленка техническая вес брутто 1,4кг втулка 0,35кг 12мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17917.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "1,4кг" },
            { name: "Толщиина", value: "12мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17918,
        name:
            "Стрейч пленка техническая вес брутто 1,4кг втулка 0,35кг 20мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17918.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "1,4кг" },
            { name: "Толщиина", value: "20мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17919,
        name:
            "Стрейч пленка техническая вес брутто 2кг втулка 0,35кг 12мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17919.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2кг" },
            { name: "Толщиина", value: "12мкр" },
        ],
        options: [],
        sizes: [],
    },

    {
        id: 17920,
        name:
            "Стрейч пленка техническая вес брутто 2кг втулка 0,36кг 17мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17920.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2кг" },
            { name: "Толщиина", value: "17мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17921,
        name:
            "Стрейч пленка техническая вес брутто 2кг втулка 0,35кг 20мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17921.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2кг" },
            { name: "Толщиина", value: "20мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17922,
        name:
            "Стрейч пленка техническая вес брутто 2,4кг втулка 0,35кг 12мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17922.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2,4кг" },
            { name: "Толщиина", value: "12мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17923,
        name:
            "Стрейч пленка техническая вес брутто 2,4кг втулка 0,35кг 20мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17923.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2,4кг" },
            { name: "Толщиина", value: "20мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17924,
        name:
            "Стрейч пленка техническая вес брутто 2,4кг втулка 0,35кг 23мкр SVETLA",
        category: 108,

        images: ["images/products/strejch/17924.jpg"],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2,4кг" },
            { name: "Толщиина", value: "23мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17925,
        name:
            "Стрейч пленка техническая вес брутто 1,4кг втулка 0,35кг 20мкр SVETLA",
        category: 108,

        images: [
            "images/products/strejch/17925.jpg",
            "images/products/strejch/17925.1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "1,4кг" },
            { name: "Толщиина", value: "20мкр" },
        ],
        options: [],
        sizes: [],
    },
    {
        id: 17926,
        name:
            "Стрейч пленка техническая вес брутто 2кг втулка 0,35кг 20мкр SVETLA",
        category: 108,

        images: [
            "images/products/strejch/17926.jpg",
            "images/products/strejch/17926.1.jpg",
        ],
        badges: [],
        rating: 3,

        availability: true,
        shortDescription:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        description:
            "Стрейч пленка техническая разной толщины для фиксации товаров на паллетах, защиты различных предметов от повреждения и загрязнения",
        features: [
            { name: "Вес", value: "2кг" },
            { name: "Толщиина", value: "20мкр" },
        ],
        options: [],
        sizes: [],
    },
    //      /108     --------------------------------------------------------
];
