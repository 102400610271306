export default {
    name: "SVETLA",
    fullName: "Svetla - Строительные ленты",
    url: "http://svetla.com.ua",
    author: {
        name: "kshvab",
        profile_url: "https://www.linkedin.com/in/kshvab/"
    },
    contacts: {
        address: "Житомир, Параджанова, 77",
        email: "hoztovarinfo@gmail.com",
        phone: "(063) 644 81 33"
    }
};
