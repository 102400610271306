// react
import React, { useState } from "react";
import classNames from "classnames";

// third-party
import { Helmet } from "react-helmet";
import axios from "axios";

// application
import PageHeader from "../shared/PageHeader";
import config from "../../config";

// blocks
import BlockMap from "../blocks/BlockMap";

// data stubs
import theme from "../../data/theme";

function SitePageContactUs() {
    const { apiPath } = config;
    const breadcrumb = [
        { title: "Главная", url: "" },
        { title: "Контакты", url: "" }
    ];

    let [nameField, setNameField] = useState("");
    let [emailField, setEmailField] = useState("");
    let [subjectField, setSubjectField] = useState("");
    let [textField, setTextField] = useState("");
    let [formErrors, setFormErrors] = useState({});
    let [isLoading, setIsLoading] = useState(false);

    function fClearForm() {
        setNameField("");
        setEmailField("");
        setSubjectField("");
        setTextField("");
        setFormErrors({});
    }

    function fChangeNameField(e) {
        setNameField(e.target.value);
    }
    function fChangeEmailField(e) {
        setEmailField(e.target.value);
    }
    function fChangeSubjectField(e) {
        setSubjectField(e.target.value);
    }
    function fChangeTextField(e) {
        setTextField(e.target.value);
    }

    function fCheckData(e) {
        e.preventDefault();
        let tempFormErrors = {};

        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!reg.test(emailField)) tempFormErrors.emailField = "Напишите корректный E-mail";

        if (!nameField.length) tempFormErrors.nameField = "Напишите свое имя";
        if (!emailField.length) tempFormErrors.emailField = "Напишите свой E-mail";
        if (!subjectField.length) tempFormErrors.subjectField = "Напишите тему сообщения";
        if (!textField.length) tempFormErrors.textField = "Напишите сообщение";

        console.log(tempFormErrors);
        setFormErrors(tempFormErrors);
        if (Object.keys(tempFormErrors).length == 0) fSubmitContactForm();
    }

    function fSubmitContactForm() {
        //need to create msgHtml

        const msgHtml = `<h2>Сообщение из сайта SVETLA</h2>
        <p><b>Отправлено через форму на странице Контакты</b></p>
        <p><b>Имя</b> - ${nameField}</p>
        <p><b>E-mail</b> - ${emailField}</p>
        <p><b>Тема сообщения</b> - ${subjectField}</p>
        <p><b>Текст сообщения</b> - ${textField}</p>`;
        const fromStr = "SVETLA Web Site";
        const subjectStr = "SVETLA сайт - Форма обратной связи на странице Контакты";

        let data = {
            msgHtml,
            fromStr,
            subjectStr
        };
        setIsLoading(true);
        axios
            .post(`${apiPath}/emailing/sendemailtoadmins`, data)
            .then(function(response) {
                console.log(response);
                fClearForm();
                setIsLoading(false);

                if (response.data.isOk) alert("Ваше сообщение успешно отправлено");
                else alert("При отправке сообщения возникла ошибка");
            })
            .catch(e => {
                fClearForm();
                setIsLoading(false);
                alert("При отправке сообщения возникла ошибка, нет связи с сервером.");
            });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Контакты — ${theme.name}`}</title>
            </Helmet>

            <BlockMap />

            <PageHeader header="Контакты" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Наш адрес</h4>

                                        <div className="contact-us__address">
                                            <p>
                                                10000, Житомир, Параджанова, 77
                                                <br />
                                                E-mail: hoztovarinfo@gmail.com
                                                <br />
                                                Телефон: +38 (063) 644 81 33
                                            </p>

                                            <p>
                                                <strong>Открыто</strong>
                                                <br />
                                                Понедельник - Пятница: 9:00 - 17:00
                                                <br />
                                                Суббота: 9:00 - 13:00
                                                <br />
                                                Воскресенье: выходной
                                            </p>

                                            <p>
                                                <br />
                                                Будем рады сотрудничеству!
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Оставьте нам сообщение</h4>
                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="contact-form-name">Ваше имя</label>
                                                    <input
                                                        type="text"
                                                        id="contact-form-name"
                                                        className={classNames("form-control", {
                                                            "is-invalid": formErrors.hasOwnProperty("nameField")
                                                        })}
                                                        value={nameField}
                                                        placeholder="Ваше имя"
                                                        onChange={fChangeNameField}
                                                    />
                                                    {formErrors.hasOwnProperty("nameField") && (
                                                        <div className="invalid-feedback">{formErrors.nameField}</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="contact-form-email">E-mail</label>
                                                    <input
                                                        type="email"
                                                        id="contact-form-email"
                                                        className={classNames("form-control", {
                                                            "is-invalid": formErrors.hasOwnProperty("emailField")
                                                        })}
                                                        value={emailField}
                                                        placeholder="E-mail"
                                                        onChange={fChangeEmailField}
                                                    />
                                                    {formErrors.hasOwnProperty("emailField") && (
                                                        <div className="invalid-feedback">{formErrors.emailField}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact-form-subject">Тема</label>
                                                <input
                                                    type="text"
                                                    id="contact-form-subject"
                                                    className={classNames("form-control", {
                                                        "is-invalid": formErrors.hasOwnProperty("subjectField")
                                                    })}
                                                    value={subjectField}
                                                    placeholder="Тема"
                                                    onChange={fChangeSubjectField}
                                                />
                                                {formErrors.hasOwnProperty("subjectField") && (
                                                    <div className="invalid-feedback">{formErrors.subjectField}</div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact-form-message">Сообщение</label>
                                                <textarea
                                                    id="contact-form-message"
                                                    className={classNames("form-control", {
                                                        "is-invalid": formErrors.hasOwnProperty("textField")
                                                    })}
                                                    value={textField}
                                                    rows="4"
                                                    onChange={fChangeTextField}
                                                />
                                                {formErrors.hasOwnProperty("textField") && (
                                                    <div className="invalid-feedback">{formErrors.textField}</div>
                                                )}
                                            </div>
                                            <button
                                                type="submit"
                                                className={classNames("btn", "btn-primary", {
                                                    "btn-loading": isLoading
                                                })}
                                                onClick={fCheckData}
                                                disabled={isLoading}
                                            >
                                                Отправить сообщение
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
