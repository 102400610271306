// types: 'parent','child' в фільтрах тип може бути "current"

const shopCategoriesArr = [
    {
        id: 101,
        name: "Клейкие ленты алюминиевые и армированные",
        type: "parent", // types: 'parent',
        article: `<p style="text-align: justify;">Отдельным видом продукции является <strong>армированная клейкая лента</strong>. Специалисты ее также называют сокращением TPL или американским скотчем. Стоит обозначить, что металла в ее составе нет, в отличие от алюминиевой ленты. Армированный скотч представляет собой продукт, созданный на базе полиэтилена, в который встроены стекловолоконные нити на этапе производства. Армирование бывает:</p>
        <ul>
        <li>поперечным,</li>
        <li>продольным,</li>
        <li>сеткообразным.</li>
        </ul>
        <p style="text-align: justify;">Какой бы тип не использовался, он будет одинаково эффективным. Такая технология усиления полиэтиленовой ленты придает ей уникальной прочности.</p>
        <p><strong>Плюсы</strong></p>
        <p style="text-align: justify;">Армированный скотч обладает следующими качествами, которых нет в других типах продукции:</p>
        <ul>
        <li>стойкость к влиянию влаги,</li>
        <li>отлично переносит перепады плюсовых температур,</li>
        <li>высокая степень адгезии,</li>
        <li>не растягивается,</li>
        <li>устойчив к механическим повреждениям.</li>
        </ul>
        <p><strong>Минусы</strong></p>
        <p style="text-align: justify;">Однако у продукции есть и негативные черты. Клейкая лента с армированием плохо переносит высокую температуру и мороз. В таких условиях она может потерять клейкость или деформироваться. Также данный тип скотча сложно разорвать, поэтому для рационального и удобного использования нужно пользоваться диспесером (приспособление для постепенной размотки и отрезания ленты).</p>
        <p><strong>Применение</strong></p>
        <p style="text-align: justify;">Благодаря своим качествам усиленный скотч позволил расширить ареал использования:</p>
        <ul style="text-align: justify;">
        <li>изолирование стыков вентиляционных коробов и труб,</li>
        <li>монтаж труб и кабелей связи,</li>
        <li>ремонт и подключение климатического оборудования,</li>
        <li>сантехнические работы (установка, ремонт, устранение протеканий),</li>
        <li>укладка напольного покрытия</li>
        <li>упаковка тяжелых грузов, фиксация коробов на поддонах, скрепление паллет.</li>
        </ul>
        <p style="text-align: justify;">В продаже представлена клейкая лента с армированием следующих вариаций:</p>
        <ul style="text-align: justify;">
        <li>прозрачная,</li>
        <li>цветная (черная, серая),</li>
        <li>однородная.</li>
        </ul>
        <p style="text-align: justify;">Обратите внимание, что оптимально приклеивать ленту при плюсовой температуре с небольшим последующим прижимом. Это поможет обеспечить максимально крепкую фиксацию и длительную эксплуатацию.</p>
        <p><strong>Алюминиевый скотч</strong></p>
        <p style="text-align: justify;">Алюминиевая клейкая лента &ndash; это особый вид технического скотча. В качестве основы используется силиконизированная бумага в комбинации с тонким металлическим листом. Клеящим веществом служит акриловый раствор.</p>
        <p style="text-align: justify;">Данный вид скотча может эксплуатироваться при любых условиях. Он отлично выдерживает температурный режим от -20 до +120 градусов Цельсия и влажность до 90%. Клейкая лента обладает высокой степенью адгезии и практически не растягивается. Она является идеальным инструментом для следующих работ:</p>
        <ul>
        <li>монтажей теплотрасс,</li>
        <li>ремонта канализационных систем,</li>
        <li>изоляция от паров,</li>
        <li>соединение кровельных материалов,</li>
        <li>герметизации труб, воздухопроводов,</li>
        <li>ремонт холодильной, климатической и другой бытовой техники,</li>
        <li>предотвращение ржавчины т.д.</li>
        </ul>
        <p style="text-align: justify;">У официальных дистрибьюторов производителя есть возможность получить качественную информативную консультацию по всем видам продукции. Под заказ изготовляется клейкая лента любой ширины и длины. Есть в реализации и стандартные размеры скотча с армированием или алюминиевой клейкой ленты.</p>`
    },
    {
        id: 102,
        name: "Клейкие ленты двусторонние",
        type: "parent",
        article: `<p style="text-align: justify;"><strong>Двусторонний скотч</strong> &ndash; это современный, многофункциональный инструмент нового поколения. Клейкая лента широко используется в сфере ремонта, монтажа, строительства и других отраслях. Благодаря универсальности она из промышленности перешла в быт. Двусторонняя клейкая лента в самом простом ее виде есть в арсенале домохозяйки, учителя и школьника. Но вариантов специального применения и самого продукта гораздо больше, чем кажется на первый взгляд.</p>
        <p style="text-align: justify;"><strong>Классификация</strong></p>
        <p style="text-align: justify;">Технический скотч разделяют на несколько видов. В зависимости от основы он может быть следующим:</p>
        <ul style="text-align: justify;">
        <li>бумажным,</li>
        <li>полипропиленовым,</li>
        <li>тканевым,</li>
        <li>вспененным.</li>
        </ul>
        <p style="text-align: justify;">Бумажная двусторонняя клейкая лента распространенный продукт для склеивания легких поверхностей (пленка, бумага, картон, ткань). Его широко применяют в полиграфии, типографии, фотопечати и медицине.</p>
        <p style="text-align: justify;">Полипропиленовый скотч отличается прочностью и высокой степенью адгезии (прилипания к поверхности). Часто используется для упаковки грузов, рекламе, полиграфии.</p>
        <p style="text-align: justify;">Двусторонняя клейкая лента на тканевой основе пользуется спросом у мастеров по ремонту и отделочным работам. На хлопковую ткань производитель наносит синтетический акриловый клей, что обеспечивает хорошее сцепление с различными покрытиями. Данный вид скотча используется для монтажа легких панелей, напольных и настенных покрытий (линолеум, пробковая подстилка, термоизоляция).</p>
        <p style="text-align: justify;">Клейкая лента на вспененной основе отличается большей толщиной от аналогичных продуктов. Ее ширина превышает 0,5 мм. Благодаря своей структуре скотч используют для монтажа поверхностей с неровной структурой. Он отлично подходит для строительных и ремонтных работ. Часто вспененную двустороннюю ленту можно встретить в мебельных цехах.</p>
        <p style="text-align: justify;"><strong>Как использовать двусторонний технический скотч</strong></p>
        <p style="text-align: justify;">Принцип работы с данным материалом достаточно прост.</p>
        <ul style="text-align: justify;">
        <li>Для качественной и надежной адгезии поверхности, что будут соединять, должны быть чистыми и сухими. В обратном случае липкий слой будет засорен пылью и другими мелкими частицами.</li>
        <li>Рекомендуется измерить и отрезать необходимую длину ленты.</li>
        <li>Аккуратно наложить ее на поверхность. Провести по ленте рукой для лучшего прилипания.</li>
        <li>Удалить защитную пленку.</li>
        <li>Наложить вторую плоскость для склеивания. Прижать ее к первой.</li>
        </ul>
        <p style="text-align: justify;"><strong>Покупка</strong></p>
        <p style="text-align: justify;">Если вам необходимо купить технический двусторонний скотч, то лучше всего обратиться к официальным представителям производителя. Они помогут подобрать оптимальный вид клейкой ленты под ваши требования (условия использования, тип продукции, температурный режим хранения и транспортировки). Цена на продукцию будет наиболее низкой, если осуществить оптовую закупку в рамках постоянного сотрудничества. Оплатить заказ можно на расчетный счет, на карту или другим способом. Доставка осуществляется рядом служб в любой город Украины.</p>`
    },
    {
        id: 103,
        name: "Клейкие ленты двусторонние вспененные",
        type: "parent",
        article: `<p style="text-align: justify;"><strong>Двусторонний вспененный скотч</strong> относится к категории технических видов клейкой ленты высокой прочности. Чаще всего его используют специалисты во время проведения ремонтных, монтажных работ, в производстве электротехники. Иногда в продаже вы можете встретить обобщенное название данного продукта 3М. Оно произошло от названия американской фирмы изобретателя, которая первой привела вспененный монтажный скотч на европейский и украинский рынки.</p>
        <p style="text-align: justify;">В отличие от других вариантов клейкой ленты для производства этой ленты используется вспененный полиэтилен. Он позволяет работать с неровными поверхностями и придать материалу большей прочности, чем пленочному аналогу. Клей бывает синтетическим каучуковым (имеет первичную клейкость и может использоваться только при плюсовой температуре) и акриловым (присуща второстепенная клейкость, но максимальная длительность в эксплуатации при различных условиях).</p>
        <p style="text-align: justify;"><strong>Классификация и применение</strong></p>
        <p style="text-align: justify;">В ассортименте магазинов и на сайтах в Интернете есть следующие типы двусторонней клейкой ленты:</p>
        <ul style="text-align: justify;">
        <li>черная с зеленым лайнером,</li>
        <li>специальная белая с белым лайнером,</li>
        <li>специальная белая с красным лайнером.</li>
        </ul>
        <p style="text-align: justify;">Двусторонняя черная клейкая лента на вспененной основе покрывается специальным ламинированным слоем клея с обеих сторон базы. Закрывается лайнером разного цвета. Высокая степень адгезии акрилового клея позволяет использовать скотч для внешних работ, как машиностроение, монтаж, строительство, грузоперевозки.</p>
        <p style="text-align: justify;">Двусторонний вспененный белый скотч обладает сильной фиксацией, основанной на каучуковом клее. Его используют для изготовления рекламной продукции, для крепления пластиковых крючков, монтажа панелей. Отлично фиксирует материалы, оптимально подходит для внутренних помещений с относительной влажностью 60-70%.</p>
        <p style="text-align: justify;">Усиленная вспененная клейкая лента с красным лайнером обладает сверхпрочной фиксацией. Она предназначается для склеивания тяжелых поверхностей в сложных погодных условиях (колебание температур, влажности, сильная механическая нагрузка). Наиболее часто данный вид скотча используют для ремонта сенсорных телефонов, склеивания в производственном масштабе деталей техники и электроники, в полиграфии (баннера, синтетические ткани).</p>
        <p style="text-align: justify;">Отечественные и заграничные производители скотча предлагают продукцию в различном формате. Представлены ленты шириной от 0,5 см до 10см, длина может быть от 1м и более. Под заказ возможно изготовление продукции под определенные параметры. Для этого необходимо обратится к официальному дистрибьютору или торговому представителю, который проконсультирует и составит заявку на покупку индивидуального характера.</p>`
    },
    {
        id: 104,
        name: "Клейкие ленты сверхпрочные, VHB пиноакрил",
        type: "parent",
        article: `<p style="text-align: justify;">Есть отрасли, которые требуют надежного соединения нестандартных материалов. С этой целью используют суперпрочные клейкие ленты VHB. Они имеют сугубо техническое применение, поскольку обладают высоким качеством адгезии, крепостью и надежностью, но и цена на данный скотч высока. Поэтому для бытового использования такая клейкая лента неуместна.</p>
        <p style="text-align: justify;">К разряду скотчей повышенной прочности относят двустороннюю клейкую ленту VHB пеноакрил. Она бывает разной ширины и длины. В продаже ее можно узнать по светло-серому цвету ленты и белому лайнеру. В качестве основы производитель использует пеноакрил (вспененный полиэтилен). Такая технология позволяет работать с неровными, шероховатыми поверхностями. Клеящим веществом выступает акриловый раствор.</p>
        <p style="text-align: justify;"><strong>Двусторонняя клейкая лента VHB обладает следующими качествами:</strong></p>
        <ol style="text-align: justify;">
        <li>Высокая степень склеивания;</li>
        <li>Хорошая механическая стойкость;</li>
        <li>Может использоваться как для внутренних, так и для наружных работ (одинаково эффективна при плюсовой и минусовой температурах);</li>
        <li>Влагостойкость до 70%;</li>
        <li>Прочная на разрыв;</li>
        <li>Стойкость к уф-лучам,</li>
        <li>Стойкость к вибрационным нагрузкам,</li>
        <li>Отлично переносит контакт с минеральными веществами и растворителями.</li>
        </ol>
        <p style="text-align: justify;"><strong>Сфера применения</strong></p>
        <p style="text-align: justify;">Как ранее обозначалось, клейкая лента из пеноакрила VHB относится к категории специальных материалов. Поэтому ее используют для сложных промышленных процессов, в частности, монтажей панелей, ремонте и сборке техники и электроники, склейки текстурных поверхностей (дерево, полипропиленовые ткани, металл).</p>
        <p style="text-align: justify;"><strong>Как использовать ленту VHB</strong></p>
        <p style="text-align: justify;">Для качественного нанесения с последующей длительной эксплуатацией клейкой ленты, поверхность нужно очистить и обезжирить. На производстве используют изопропиловый спирт или 50% раствор спирта с водой.</p>
        <p style="text-align: justify;">Иногда шероховатые поверхности (дерево, пластик, окрашенные или полакированные изделия) дополнительно грунтуют. Данная процедура значительно увеличивает эффективность адгезии.</p>
        <p style="text-align: justify;">Особого внимания требует и специальная основа. Таковой являются стеклянные, керамические, металлические и каучуковые поверхности. Они имеют жирный защитный слой, который нужно удалить перед нанесением ленты.</p>
        <p style="text-align: justify;">Также для надежного приклеивания понадобится прижим. В зависимости от масштабности производства используется резиновый валик или специальный станок, что оказывает умеренное давление на соединяемые двусторонним скотчем плоскостей.</p>
        <p style="text-align: justify;"><strong>Заказ</strong></p>
        <p style="text-align: justify;">Если необходимо купить суперпрочные клейкие ленты VHB из пеноакрила, то свяжитесь с представителями производителя. Менеджеры подробно проконсультируют о вариантах и возможностях продукции. В рамках сотрудничества возможны розничные и оптовые закупки технического скотча. Оплата и доставка производится одним из ряда способов по предварительной договоренности.</p>`
    },
    {
        id: 105,
        name: "Клейкие ленты прозрачные и цветные",
        type: "parent",
        article: `<p style="text-align: justify;">Клейкая лента давно вошла в жизнь всех людей на планете. Женщины, мужчины привыкли использовать скотч во многих бытовых вопросах. Технические ленты также продолжают применятся в различных сферах (монтаж, ремонт, строительство, производство, реклама, дизайн). Существует много вариантов данного товара, каждому из них свойственны определенные характеристики (степень адгезии, прочность, цвет, термостойкость, влагостойкость и т.д.).</p>
        <p style="text-align: justify;"><strong>Классификация</strong></p>
        <p style="text-align: justify;">В зависимости от предназначения прозрачная клейкая лента бывает следующих типов:</p>
        <ol style="text-align: justify;">
        <li>Упаковочная. Применяется для запаковки грузов, посылок. Наиболее часто при помощи скотча скрепляют картон, бумагу, полиэтилен. Он имеет ширину 10-15 см, что помогает отлично защитить стык материалов. Продается в бобинах.</li>
        <li>Канцелярская липкая лента - наиболее распространенный продукт обихода. Он представляет собой узкие полоски от 0,5 до 1-3 см. Используют скотч для ремонта тетрадей, книг и другой бумажной продукции.</li>
        <li>Малярная клейкая лента нужна для покрасочных работ. Базой служит бумага, что отлично впитывает жидкости, но не промокает насквозь. Малярный скотч применяют для маркировки, защиты, получения ровных линий во время малярных процессов.</li>
        <li>Технический скотч предназначается для склеивания сложных поверхностей. Он обладает прочностью, повышенной адгезией, стойкостью к влаге, высокой температуре. Техническая клейкая лента используется в мебельной индустрии, производстве полиграфической продукции, фотопечати, рекламе, монтажах и ремонте.</li>
        <li>Пломбировочная лента &ndash; это особый продукт, что представляет собой узкую полоску с липким слоем. Она примется для маркировки цельности посылок, грузов и другого рода продукции. ЕЕ неразрывность дает понять, что упаковка осталась невредимой.</li>
        </ol>
        <p style="text-align: justify;">В качестве базового слоя для изготовления клейкой ленты могут использовать целый ряд материалов:</p>
        <ul style="text-align: justify;">
        <li>ткань,</li>
        <li>полипропилен,</li>
        <li>бумагу,</li>
        <li>вспененную основу.</li>
        </ul>
        <p style="text-align: justify;">Последний вид считается наиболее крепким и надежным. Он используется в мебельной индустрии и при монтаже неровных поверхностей и плоскостей.</p>
        <p style="text-align: justify;">В свою очередь полипропиленовая клейкая лента может быть:</p>
        <ul style="text-align: justify;">
        <li>прозрачная,</li>
        <li>цветная (полупрозрачная тонированная, непрозрачная),</li>
        <li>односторонняя,</li>
        <li>двусторонняя.</li>
        </ul>
        <p style="text-align: justify;">Прозрачный скотч позволяет максимально незаметно провести склеивание. Цветной же вариант служит маркировкой продукции, а также используется для производства ленты с рисунками (логотипами, надписями или узорами).</p>
        <p style="text-align: justify;">Малярная лента имеет желтый цвет. Упаковочный скотч производится в прозрачном формате или в полупрозрачном коричневом цвете в тон картона. Часто на ленту наносят обозначение о свойствах груза (хрупкость, жидкость или химическую небезопасность).</p>
        <p style="text-align: justify;">Прочный двусторонний скотч технического предназначения имеет красный защитный слой. Он обладает повышенной плотностью, давая возможность его легкого удаления с липкого слоя. В то время, как бытовому, свойственна прозрачная или белая защитная пленка.</p>
        <p style="text-align: justify;"><strong>Где заказать?</strong></p>
        <p style="text-align: justify;">Наиболее выгодно купить техническую липкую ленту в прозрачном или цветном варианте у производителя или через официального дистрибьютора. Профессиональные консультанты помогут подобрать продукцию в соответствии с вашими пожеланиями. Доступна розничная и оптовая покупка. Оплата и доставка осуществляется одним из ряда способов.</p>`
    },
    {
        id: 106,
        name: "Клейкие ленты специальные, сигнальные",
        type: "parent",
        article: `<p style="text-align: justify;"><strong>Сигнальный скотч</strong> &ndash; это особый вид продукции, что отличается от аналогичных лент целым рядом свойств. Он широко используется в индивидуальных, промышленных, технических целях. Основное предназначение сигнальной ленты &ndash; дистанционное уведомление. Цвет дает понять ту или другую информацию прохожему о приближающемся объекте или территории.</p>
        <p style="text-align: justify;"><strong>Виды</strong></p>
        <p style="text-align: justify;">В продаже существуют несколько видов сигнальной клеящейся ленты:</p>
        <ul style="text-align: justify;">
        <li>светоотражающая,</li>
        <li>однотонная,</li>
        <li>разноцветная.</li>
        </ul>
        <p style="text-align: justify;"><strong>Использование</strong></p>
        <p style="text-align: justify;">Первый вид скотча отличается специальной технологией производства. Она позволила создать многослойный рисунок способный отражать даже минимальный луч света. Данный вид ленты используется для маркировки транспортных средств (прицепы, грузовики, трейлеры, контейнеры, тракторы) и дорожных знаков, столбцов. Существуют разные виды ленты по дальности светоотражения от 5-10 м до 50-100м, которые применяются для знаков на дорогах, автострадах, улицах городов.</p>
        <p style="text-align: justify;">Два последующие виды сигнальной ленты с клейким слоем имеют информативное предназначение. Могут клеиться на движимые (кары, подъемники, краны) и стационарные объекты (станки, шкафчики, электрощитовые), а также на поверхности (пол, потолок, стены).</p>
        <p style="text-align: justify;">Сигнальную ленту желтого, красно-черного, бело-черного, желто-черного цветов принято использовать для маркировки служебных зон на производстве. Например, в красную зону вход воспрещен, а в желто-черную&nbsp; - опасен или ограничен. Таким образом, лента выполняет следующие функции:</p>
        <ol style="text-align: justify;">
        <li>Обеспечение безопасности труда. Цвет сигнализирует и позволяет дистанционно предупредить сотрудника о приближении к зоне или объекту. Тем самым снизить риск травм на производстве.</li>
        <li>Разграничение пространства. Определенное цветовое решение разделяет общую площадь на зоны с определенным целевым предназначением.</li>
        <li>Запретная. В случаях образования чрезвычайных происшествий требуется оградить территорию. С этой функцией отлично справляется красная или желтая клейкая лента, что отлично фиксируется на любых вертикальных поверхностях.</li>
        </ol>
        <p style="text-align: justify;"><strong>Специальный скотч обладает следующими свойствами:</strong></p>
        <ul style="text-align: justify;">
        <li>повышенной степенью адгезии (приклеивается к неровным, шероховатым и гладким поверхностям),</li>
        <li>механической устойчивостью (защитный слой не дает ленте повредиться от случайных и постоянных трений),</li>
        <li>влагостойкостью (не боится влияния воды или повышенной влажности в помещении, можно протирать губкой),</li>
        <li>крепость (скотч сложно разорвать руками или даже удалить с поверхности без специальных средств).</li>
        </ul>
        <p style="text-align: justify;"><strong>Где заказать</strong></p>
        <p style="text-align: justify;">Наиболее низкая цена на специальные клейкие ленты у производителя. Официальные дистрибьюторы обладают полной информацией об ассортименте, технических характеристиках продукции и актуальной стоимости. Возможна розничная и оптовая покупка сигнальной ленты. Оплату и доставку товара осуществляют одним из способов на выбор покупателя.</p>`
    },
    {
        id: 107,
        name: "Малярная клейкая лента желтая",
        type: "parent",
        article: `<p style="text-align: justify;">Процесс ремонта неразрывно связан с использованием различных клеев, красок и других растворов. Особенной во время покрасочных работ часто на чистой поверхности появляются неожиданные пятна или брызги. Специалисты, используя желтую малярную ленту, осуществляют шпаклевания, покраску, грунтование, поклейку максимально аккуратно. Качественный и простой инструмент значительно упрощает целый ряд процессов.</p>
        <p style="text-align: justify;"><strong>Что такое малярная лента</strong></p>
        <p style="text-align: justify;">Данный предмет еще называют креп-лентой. Малярный скотч &ndash; это ровные длинные полоски специальной гофрированной или крепированной бумаги с липкий слоем. База отличается от обычного материла повышенной крепостью, но при этом ее можно вручную разорвать. Бумага достаточно легко впитывает и задерживает на своей поверхности брызги воды, краски или другой жидкости (шпаклевка, грунт, клей), но не промокает насквозь. Таким образом, поверхность под малярной лентой остается чистой.</p>
        <p style="text-align: justify;">В качестве клея для скотча используют акриловый или синтетический каучуковый раствор. Он имеет качественные показатели адгезии (сцепления с поверхностью). Малярная лента отлично держится на стекле, стене, кафеле, зеркале, но в отличие от обычного скотча она не оставляет липкого слоя после себя и достаточно легко удаляется с поверхности. Даже если вы заметили остатки липкости, то он очень легко удаляются влажно губкой.</p>
        <p style="text-align: justify;"><strong>Варианты</strong></p>
        <p style="text-align: justify;">В продаже большое количество разных вариантов скотча для малярных работ. На полках строительных супермаркетов и в интернет-магазинах представлены товары разной ширины (от 0,5 см до 10-15 см), длины (в рулоне/ бобине может быть от 1м ленты). Таким образом, покупатель подберет для себя оптимальный вариант для того или другого вида работ. База малярного скотча бывает белой, желтой.</p>
        <p style="text-align: justify;"><strong>Применение</strong></p>
        <p style="text-align: justify;">Спектр вариантов использования креп-ленты очень обширен. Расходный вариант обеспецивает следующие опции для ремонтных работ:</p>
        <ul style="text-align: justify;">
        <li>Протекция. Когда необходимо защитить поверхность от случайных или неизбежных загрязнений. Например, когда красят деревянные окна, а стекло должно остаться чистым. Также используют ленту, когда покрывают новым слоем краски или шпаклевки стены, а потолок или другая стена должны остаться в первичном состоянии.</li>
        <li>Разграничительная функция. Она предусматривает формирование пограничной зоны. Например, когда необходимо сформировать идеальный стык двух цветов или материалов с разной фактурой (обои и штукатурка).</li>
        <li>Формирование ровных краев. Часто для идеального отверстия мастера используют малярную ленту. Она отлично справляется с данной задачей.</li>
        <li>Разметка. На шероховатой поверхности бумаги можно обозначить отметки или надписи, что необходимы для ремонта.</li>
        </ul>
        <p style="text-align: justify;"><strong>Как использовать</strong></p>
        <p style="text-align: justify;">При наклеивании желтой малярной ленты нужно четко зафиксировать один край, второй рукой разматывать бобину. Зафиксировать второй край, оторвать скотч с запасом в 2-3 см. Провести вдоль ленты рукой или пластиковым шпателем для сцепления поверхностей. Перпендикулярную полосу следует наклевать внахлест в 2-5 см, чтобы предотвратить проникания жидкостей в стыке.</p>
        <p style="text-align: justify;">По окончанию ремонтных работ, при полном высыхании краски, удалите ленту.</p>`
    },
    {
        id: 108,
        name: "Стрейч пленки технические",
        type: "parent",
        article: `<p style="text-align: justify;"><strong>Стрейч пленка</strong> &ndash; это уникальный продукт 21 столетия, что широко используется в быту и промышленности. По происхождению он представляет собой поливинилхлоридный материал с повышенной растяжимостью и способностью к возвращению первичного стояния.</p>
        <p style="text-align: justify;"><strong>Классификация</strong></p>
        <p style="text-align: justify;">Всю пленку можно разделить на два основные класса:</p>
        <ol style="text-align: justify;">
        <li>Пищевой. Стрейч используют для запаковки продуктов питания. Имеет минимальную толщину и абсолютно безопасен для здоровья человека. Пленку можно купить в супермаркетах и магазинах с бытовой химией в небольших рулонах по 5-10 м. Для продуктовых точек выпускают пленку в больших бобинах.</li>
        <li>Технический. Предусматривает масштабное использование для малой и большой промышленности. Основными вариантами применения являются:</li>
        </ol>
        <ul>
        <li>упаковка,</li>
        <li>транспортировка,</li>
        <li>хранение единичных и паллетированых грузов.</li>
        </ul>
        <p style="text-align: justify;">Техническая стрейч пленка является расходным материалом. Она продается в бобинах разной шириной и длиной. Возможно ручное и полуавтоматическое использование. Например,&nbsp; вручную запаковывают посылки на отделениях почтовых служб. А вот паллетоупаковочные машины применяются на складах, производственных цехах и в аэропортах, где скорость и масштабы работ значительные.</p>
        <p style="text-align: justify;">Стрейч технического рода различают 2 вариантов, полагаясь на плотности материала:</p>
        <ol style="text-align: justify;">
        <li>Прозрачный имеет толщину 10-17 мк.</li>
        <li>Цветной (непрозрачный синий, красный, черный) характеризуется плотностью 20-30 мк. Самая толстая черная пленка.</li>
        </ol>
        <p style="text-align: justify;"><strong>Преимущества</strong></p>
        <ol style="text-align: justify;">
        <li>Растяжимость. В отличие от бумаги и других упаковочных материалов, технический стрейч способен растягиваться до 15 раз от первичного состояния и возвращаться обратно. Это помогает плотно упаковывать грузы нестандартной формы.</li>
        <li>Стойкость к внешней среде. Пленка отлично выдерживает влияние влаги, мороза и повышенной температуры, пыли. Ее эффективно используют для хранения товаров на складах и при перевозке. Однако перепады температуры могут спровоцировать появление конденсата на поверхности пленки, поэтому обмотка должна быть максимально плотной, чтобы влага не образовалась внутри упаковки.</li>
        <li>Герметичность. Материал обладает повышенной адгезией между слоями. Поэтому при качественной упаковке формируется почти вакуумное пространство, что не дает проникать влаге и пыли внутрь коробки или паллеты.</li>
        <li>Механическая прочность. Плотный стрейч отлично справляется со случайными трениями и скользящими повреждениями. Он берет нагрузку на себя, тем самым оставляя поверхность груза невредимой.</li>
        <li>Защита от взглядов и ультрафиолета. Черная техническая стрейч пленка плотно покрывает поверхность, не давая солнечным лучам проникать сквозь нее. Также плотная пленка исключает возможность увидеть составляющие посылки.</li>
        <li>Легкость. Стрейч имеет минимальный вес. Поэтому он широко используется в грузоперевозках, не добавляя существенной нагрузки на транспорт.</li>
        <li>Безопасность. Материал не имеет вредных испарений или покрытий. Поэтому его можно применять без средств индивидуальной защиты</li>
        </ol>
        <p style="text-align: justify;"><strong>Недостатки</strong></p>
        <p style="text-align: justify;">Техническая и пищевая пленка практически не имеет недостатков. Таковым можно назвать разве что низкую экологичность. Как и любой полиэтилен, материал требует специальной утилизации, но он может быть использован вторично после переработки.</p>`
    }
];

export default shopCategoriesArr;
