// react
import React from "react";

export default function BlockMap() {
    return (
        <div className="block-map block">
            <div className="block-map__body">
                <iframe
                    title="Google Map"
                    //  src="https://maps.google.com/maps?q=Holbrook-Palmer%20Park&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5100.897728361409!2d28.711054072449333!3d50.26487700063321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472c63c7a7edc9db%3A0x2e8fbb223e9e7dbc!2sSvarka%20Club!5e0!3m2!1suk!2scz!4v1577042181111!5m2!1suk!2scz"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                />
            </div>
        </div>
    );
}
