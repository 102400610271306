// react
import React from "react";
import { useParams } from "react-router";

// third-party
import { Helmet } from "react-helmet";

// application
import CategorySidebar from "./CategorySidebar";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import SitePageNotFound from "../site/SitePageNotFound";
// data stubs
import products from "../../data/shopProducts";
import categories from "../../data/shopCategories";
import theme from "../../data/theme";

function ShopPageCategory(props) {
    let { id } = useParams();
    let category = categories.find(item => {
        return item.id == id;
    });

    if (category == undefined) {
        return <SitePageNotFound />;
    }

    let catProducts = products.filter(item => {
        return item.category == id;
    });

    const columns = 3,
        viewMode = "grid",
        sidebarPosition = "start";

    const breadcrumb = [
        { title: "Главная", url: "/" },
        { title: category.name, url: `/shop/category/:${id}` }
    ];
    let content;

    const offcanvas = columns === 3 ? "mobile" : "always";

    let article = "";
    if (category.article) article = <div dangerouslySetInnerHTML={{ __html: category.article }} />;

    const sidebar = <div className="shop-layout__sidebar">{<CategorySidebar offcanvas={offcanvas} />}</div>;

    content = (
        <div className="container">
            <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                {sidebar}
                <div className="shop-layout__content">
                    <div className="block">
                        <ProductsView
                            products={catProducts}
                            layout={viewMode}
                            grid="grid-3-sidebar"
                            limit={15}
                            offcanvas={offcanvas}
                        />
                    </div>
                    {article}
                </div>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${category.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={category.name} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

export default ShopPageCategory;
