// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function WidgetPosts(props) {
    const { posts } = props;

    const postsList = posts.map(post => (
        <div key={post.id} className="widget-posts__item">
            <div className="widget-posts__image">
                <Link to={"/blog/post/" + post.id}>
                    <img src={post.image} alt="" />
                </Link>
            </div>
            <div className="widget-posts__info">
                <div className="widget-posts__name">
                    <Link to={"/blog/post/" + post.id}>{post.title}</Link>
                </div>
                <div className="widget-posts__date">{post.date}</div>
            </div>
        </div>
    ));

    return (
        <div className="widget-posts widget">
            <h4 className="widget__title">Свежие посты</h4>
            <div className="widget-posts__list">{postsList}</div>
        </div>
    );
}

WidgetPosts.propTypes = {
    /**
     * array of posts
     */
    posts: PropTypes.array
};
WidgetPosts.defaultProps = {
    posts: []
};

export default WidgetPosts;
