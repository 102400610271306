// react
import React, { useState } from "react";
import config from "../../config";
import axios from "axios";
import classNames from "classnames";

function FooterNewsletter() {
    const { apiPath } = config;

    let [emailField, setEmailField] = useState("");
    let [formError, setFormError] = useState(false);
    let [isLoading, setIsLoading] = useState(false);

    function fClearForm() {
        setEmailField("");
        setFormError(false);
    }

    function fChangeEmailField(e) {
        setEmailField(e.target.value);
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!reg.test(emailField) || emailField == "") setFormError(true);
        else setFormError(false);
    }

    function fCheckData(e) {
        e.preventDefault();
        if (!formError) fSubmitContactForm();
    }

    function fSubmitContactForm() {
        //need to create msgHtml

        const msgHtml = `<h2>Сообщение из сайта SVETLA</h2>
            <p><b>Отправлено через форму в подвале сайта</b></p>
            <p><b>Посетитель хочет подписаться на новости</b></p>
            <p><b>E-mail</b> - ${emailField}</p>`;
        const fromStr = "SVETLA Web Site";
        const subjectStr = "SVETLA сайт - Новая подписка на новости";

        let data = {
            msgHtml,
            fromStr,
            subjectStr,
        };
        setIsLoading(true);
        axios
            .post(`${apiPath}/emailing/sendemailtoadmins`, data)
            .then(function (response) {
                console.log(response);
                fClearForm();
                setIsLoading(false);

                if (response.data.isOk) alert("Ваше сообщение успешно отправлено");
                else alert("При отправке сообщения возникла ошибка");
            })
            .catch((e) => {
                fClearForm();
                setIsLoading(false);
                alert("При отправке сообщения возникла ошибка, нет связи с сервером.");
            });
    }

    const socialLinks = [
        {
            key: "facebook",
            url: "https://www.facebook.com/Svetlazt/",
            iconClass: "fab fa-facebook-f",
        },

        {
            key: "instagram",
            url: "https://www.instagram.com/svetlazt/",
            iconClass: "fab fa-instagram",
        },

        /*
        {
            key: "twitter",
            url: "",
            iconClass: "fab fa-twitter"
        },
        {
            key: "youtube",
            url: "",
            iconClass: "fab fa-youtube"
        },
        {
            key: "rss",
            url: "",
            iconClass: "fas fa-rss"
        }
    */
    ];
    /*
    const socialLinksList = socialLinks.map(item => (
        <li key={item.key} className={`footer-newsletter__social-link footer-newsletter__social-link--${item.key}`}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={item.iconClass} />
            </a>
        </li>
    ));
*/
    let socialLinksList = [
        <li key={1} className={`footer-newsletter__social-link footer-newsletter__social-link--facebook`}>
            <a href={"https://www.facebook.com/Svetlazt/"} target="_blank" rel="noopener noreferrer">
                <i className={"fab fa-facebook-f"} />
            </a>
        </li>,
        <li key={2} className={`footer-newsletter__social-link footer-newsletter__social-link--instagram`}>
            <a href={"https://www.instagram.com/svetlazt/"} target="_blank" rel="noopener noreferrer">
                <i className={"fab fa-instagram"} />
            </a>
        </li>,
    ];

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">На связи</h5>
            <div className="footer-newsletter__text">
                Подпишитесь на нашу рассылку, чтобы быть в курсе всех новинок и акций!
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">
                    Email Address
                </label>
                <input
                    type="email"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="E-mail..."
                    value={emailField}
                    onChange={fChangeEmailField}
                />

                <button
                    className={classNames("footer-newsletter__form-button", "btn", "btn-primary", {
                        "btn-loading": isLoading,
                    })}
                    disabled={isLoading}
                    onClick={fCheckData}
                >
                    Подписаться
                </button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Присоеденяйтесь к нам в соцсетях
            </div>

            <ul className="footer-newsletter__social-links">{socialLinksList}</ul>
        </div>
    );
}

export default FooterNewsletter;
