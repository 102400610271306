// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HomePageProductsView from "./HomePageProductsView";
import { sidebarClose } from "../../store/sidebar";

// data stubs
import products from "../../data/shopProducts";

function HomePageShopBlock(props) {
    const columns = 5;
    const viewMode = "grid";

    let content;

    const offcanvas = columns === 3 ? "mobile" : "always";

    content = (
        <div className="container">
            <div className="block">
                <HomePageProductsView
                    products={products}
                    layout={viewMode}
                    grid="grid-4-full"
                    limit={15}
                    offcanvas={offcanvas}
                />
            </div>
        </div>
    );

    return <React.Fragment>{content}</React.Fragment>;
}

HomePageShopBlock.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"])
};

HomePageShopBlock.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start"
};

const mapStateToProps = state => ({
    sidebarState: state.sidebar
});

const mapDispatchToProps = {
    sidebarClose
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageShopBlock);
