// react
import React, { useState, useContext } from "react";
import { Redirect } from "react-router";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
// application
import { Search20Svg } from "../../svg";

function Search() {
    let [searchStr, setSearchStr] = useState("");
    let [searchActivated, setSearchActivated] = useState(false);
    let history = useHistory();

    if (searchActivated) {
        setSearchActivated(false);
        history.push(`/shop/search/${searchStr}`);
        setSearchStr("");
    }

    function fChangeSearchStr(e) {
        setSearchStr(e.target.value);
    }

    function fSearchActivation(e) {
        e.preventDefault();
        setSearchActivated(true);
    }

    return (
        <div className="search">
            <form className="search__form" action="" onSubmit={fSearchActivation}>
                <input
                    className="search__input"
                    name="search"
                    placeholder="Поиск по товарам"
                    aria-label="Site search"
                    autoComplete="off"
                    value={searchStr}
                    onChange={fChangeSearchStr}
                />
                <button className="search__button" type="submit">
                    <Search20Svg />
                </button>
                <div className="search__border" />
            </form>
        </div>
    );
}

export default Search;
