// react
import React from "react";

const ProductTabDescription = props => {
    const { product } = props;
    return (
        <div className="typography">
            <h3 className="mb-2">Полное описание продукта</h3>
            {product.description}
        </div>
    );
};

export default ProductTabDescription;
